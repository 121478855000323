import { defineMessage } from 'react-intl'

export const HOW_TO_DISCONNECT_DIALOG_TITLE = defineMessage({
    defaultMessage: 'How to disconnect Apple Health',
    description:
        'The title, for the modal on mobile, that shows the user how to disconnect from Apple Health'
})

export const HOW_TO_CONNECT_DIALOG_TITLE = defineMessage({
    defaultMessage: 'How to connect Apple Health',
    description:
        'The title, for the modal on the web, that shows the user how to connect to Apple Health'
})

export const CLOSE_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Close',
    description: 'Label for button that closes the modal'
})

export const PRIVACY_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Privacy info',
    description: 'Label for button that shows the user privacy info'
})
