import { useMemo } from 'react'
import { useGetUserQuery } from '..'
import type { ApolloError } from '@apollo/client'

export type useGetWearablesUserReturnType =
    | {
          hasActiveOuraDirectConnection: undefined
          hasOuraDirectConnection: undefined
          hasActiveAppleHealthDirectConnection: undefined
          hasActiveHumanApiConnection: undefined
          hasAnyActiveConnection: undefined
          hasAnyConnection: undefined
          hasHumanApiConnection: undefined
          hasAppleHealthDirectConnection: undefined
          isHumanApiUser: undefined
          isReturningUser: undefined
          isLoading: true
          error: undefined
      }
    | {
          hasActiveOuraDirectConnection: undefined
          hasOuraDirectConnection: undefined
          hasActiveAppleHealthDirectConnection: undefined
          hasActiveHumanApiConnection: undefined
          hasAnyActiveConnection: undefined
          hasAnyConnection: undefined
          hasHumanApiConnection: undefined
          hasAppleHealthDirectConnection: undefined
          isHumanApiUser: undefined
          isReturningUser: undefined
          isLoading: false
          error: ApolloError
      }
    | {
          hasActiveOuraDirectConnection: boolean
          hasOuraDirectConnection: boolean
          hasActiveAppleHealthDirectConnection: boolean
          hasActiveHumanApiConnection: boolean
          hasAnyActiveConnection: boolean
          hasAnyConnection: boolean
          hasHumanApiConnection: boolean
          hasAppleHealthDirectConnection: boolean
          isHumanApiUser: boolean
          isReturningUser: boolean
          isLoading: false
          error: undefined
      }

/**
 * A hook that returns information about the wearables user and their connections.
 *
 */
export const useGetWearablesUser = (): useGetWearablesUserReturnType => {
    const { data, loading, error } = useGetUserQuery()

    return useMemo(() => {
        const userData = data?.wearables.users.getUser

        const {
            hasActiveOuraDirectConnection = false,
            hasOuraDirectConnection = false,
            hasActiveAppleHealthDirectConnection = false,
            hasActiveHumanApiConnection = false,
            hasAppleHealthDirectConnection = false,
            hasHumanApiConnection = false,
            isHumanApiUser = false
        } = { ...userData }

        const isReturningUser = !!userData

        const hasAnyActiveConnection =
            hasActiveAppleHealthDirectConnection ||
            hasActiveHumanApiConnection ||
            hasActiveOuraDirectConnection

        const hasAnyConnection =
            hasOuraDirectConnection ||
            hasAppleHealthDirectConnection ||
            hasHumanApiConnection ||
            hasAnyActiveConnection

        if (loading) {
            return {
                hasActiveOuraDirectConnection: undefined,
                hasOuraDirectConnection: undefined,
                hasActiveAppleHealthDirectConnection: undefined,
                hasActiveHumanApiConnection: undefined,
                hasAnyActiveConnection: undefined,
                hasHumanApiConnection: undefined,
                hasAppleHealthDirectConnection: undefined,
                hasAnyConnection: undefined,
                isHumanApiUser: undefined,
                isReturningUser: undefined,
                isLoading: true,
                error: undefined
            }
        }

        if (error) {
            return {
                hasActiveOuraDirectConnection: undefined,
                hasOuraDirectConnection: undefined,
                hasActiveAppleHealthDirectConnection: undefined,
                hasActiveHumanApiConnection: undefined,
                hasAnyActiveConnection: undefined,
                hasHumanApiConnection: undefined,
                hasAppleHealthDirectConnection: undefined,
                hasAnyConnection: undefined,
                isHumanApiUser: undefined,
                isReturningUser: undefined,
                isLoading: false,
                error
            }
        }

        return {
            hasActiveOuraDirectConnection,
            hasOuraDirectConnection,
            hasActiveAppleHealthDirectConnection,
            hasActiveHumanApiConnection,
            hasAnyActiveConnection,
            hasHumanApiConnection,
            hasAppleHealthDirectConnection,
            hasAnyConnection,
            isHumanApiUser,
            isReturningUser,
            isLoading: false,
            error: undefined
        }
    }, [data, loading, error])
}
