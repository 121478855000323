export enum AppleHealthPermissionType {
    STEPS = 'Steps',
    SLEEP = 'SleepAnalysis'
}

export enum AppleHealthAccessLevel {
    NOT_DETERMINED = 'NotDetermined',
    SHARING_DENIED = 'SharingDenied',
    SHARING_AUTHORIZED = 'SharingAuthorized'
}
