import { Box, MenuItem, SelectChangeEvent, Stack } from '@mui/material'
import { format } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafDatePicker,
    LeafTextField,
    LeafSelect,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, {
    ChangeEvent,
    ChangeEventHandler,
    memo,
    useCallback,
    useMemo
} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useManualActivities } from '../../hooks'
import { ManualActivity } from 'src/hooks/useManualActivities/useManualActivities'

const MESSAGES = defineMessages({
    activityLabel: {
        defaultMessage: 'Activity',
        description: 'activity dropdown label text'
    },
    activityPlaceholder: {
        defaultMessage: 'Choose an activity',
        description: 'activity dropdown placeholder text'
    },
    activityDateLabel: {
        defaultMessage: 'Activity date',
        description: 'date input label text'
    },
    durationLabel: {
        defaultMessage: 'Duration (minutes)',
        description: 'duration input label text'
    },
    minAdornment: {
        defaultMessage: 'min.',
        description: 'input adornment for the activity duration in minutes'
    }
})

export interface ManualActivityCardProps {
    startDate: Date
    selectedActivity?: ManualActivity
    activityDate: string
    activityDuration: number
    invalidInput: boolean
    setSelectedActivity: (manualActivity: ManualActivity | undefined) => void
    setActivityDate: (date: string) => void
    setActivityDuration: (duration: number) => void
}

const ManualActivityCard: React.FC<ManualActivityCardProps> = ({
    startDate,
    selectedActivity,
    activityDate,
    activityDuration,
    invalidInput,
    setSelectedActivity,
    setActivityDate,
    setActivityDuration
}) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()
    const {
        manualActivities,
        loading: manualActivitiesLoading,
        error: manualActivitiesError
    } = useManualActivities()

    const manualActivityOptions = useMemo(() => {
        if (manualActivitiesLoading || manualActivitiesError) {
            return []
        }
        return Object.values(manualActivities)
    }, [manualActivities, manualActivitiesError, manualActivitiesLoading])

    const handleChangeDate: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            setActivityDate(event.target.value)
        },
        [setActivityDate]
    )

    const handleChangeActivity = useCallback(
        (event: SelectChangeEvent<unknown>) => {
            if (manualActivitiesLoading || manualActivitiesError) {
                return
            }
            const activity = manualActivities[event.target.value as string]
            setSelectedActivity(activity)
        },
        [
            manualActivities,
            manualActivitiesError,
            manualActivitiesLoading,
            setSelectedActivity
        ]
    )

    const handleChangeDuration: ChangeEventHandler<HTMLInputElement> =
        useCallback(
            (event) => {
                const value = Math.max(0, Number(event.target.value))
                setActivityDuration(value)
            },
            [setActivityDuration]
        )

    return (
        <Stack gap={2}>
            <LeafDatePicker
                aria-label={formatMessage(MESSAGES.activityDateLabel)}
                id="activity-date-picker-input"
                type="date"
                color="primary"
                variant="outlined"
                label={formatMessage(MESSAGES.activityDateLabel)}
                value={activityDate}
                onChange={handleChangeDate}
                inputProps={{
                    max: format(new Date()),
                    min: format(startDate)
                }}
                formControlProps={{
                    fullWidth: true
                }}
            />
            <LeafSelect
                aria-label={formatMessage(MESSAGES.activityLabel)}
                id="activity-input-input"
                variant="outlined"
                label={formatMessage(MESSAGES.activityLabel)}
                placeholderText={formatMessage(MESSAGES.activityPlaceholder)}
                value={selectedActivity?.id}
                onChange={handleChangeActivity}
                formControlProps={{
                    fullWidth: true
                }}
                error={invalidInput}
            >
                {manualActivityOptions.map(({ description, id }, index) => (
                    <MenuItem key={id} value={id}>
                        <CoreTypography customVariant="form">
                            {description}
                        </CoreTypography>
                    </MenuItem>
                ))}
            </LeafSelect>
            <Box display="flex" alignItems="flex-end" gap={1}>
                <LeafTextField
                    aria-label={formatMessage(MESSAGES.durationLabel)}
                    id="activity-duration-input"
                    variant="outlined"
                    type="number"
                    label={formatMessage(MESSAGES.durationLabel)}
                    value={activityDuration.toString()}
                    error={invalidInput}
                    onChange={handleChangeDuration}
                    fullWidth
                />
                <CoreTypography
                    variant="body1"
                    marginBottom={2}
                    color={theme.palette.text.secondary}
                >
                    {formatMessage(MESSAGES.minAdornment)}
                </CoreTypography>
            </Box>
        </Stack>
    )
}
export default memo(ManualActivityCard)
