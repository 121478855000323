import { Box, Button, DialogContent } from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useMemo } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { openHealthApp } from '../../utils'

const APPLE_HEALTH_CONNECT_FLOW_SETUP_COMPLETE_HEADER = defineMessage({
    defaultMessage: 'Set up complete!',
    description:
        "Header letting the user know they've finished a set up process"
})
const APPLE_HEALTH_CONNECT_FLOW_SETUP_COMPLETE_SUBHEADER = defineMessage({
    defaultMessage:
        'You’ve successfully connected your Apple Health to Thrive.',
    description:
        "Subheader explaining to the user that they've finished the set up process for connecting Apple Health"
})
const APPLE_HEALTH_CONNECT_FLOW_SETUP_COMPLETE_DONE_BUTTON_TEXT = defineMessage(
    {
        defaultMessage: 'Done',
        description:
            'Text for a "Done" button in a set-up wizard, which lets the user complete setup'
    }
)

const APPLE_HEALTH_CONNECT_FLOW_SETUP_COMPLETE_CLOSE_BUTTON_TEXT =
    defineMessage({
        defaultMessage: 'Close',
        description:
            'Text for a "Close" button in a set-up wizard, which lets the user close the wizard and cancel setup'
    })

const APPLE_HEALTH_CONNECT_FLOW_SETUP_FAILED_HEADER = defineMessage({
    defaultMessage: 'Set up failed',
    description: 'Header letting the user know the setup process has failed'
})

const APPLE_HEALTH_CONNECT_FLOW_SETUP_FAILED_NO_ACCESS_SUBHEADER =
    defineMessage({
        defaultMessage:
            'Thrive is unable to access your health data. You can grant access in your Health app settings under Privacy > Apps > Thrive.',
        description:
            'Subheader explaining to the user how to grant health data access through Apple Health'
    })

const APPLE_HEALTH_CONNECT_FLOW_SETUP_FAILED_UNKNOWN_ERROR_SUBHEADER =
    defineMessage({
        defaultMessage: "Something's wrong on our end. Please try again later.",
        description:
            'Text letting the user know that there was an issue processing their request'
    })

const APPLE_HEALTH_CONNECT_FLOW_SETUP_FAILED_OPEN_APPLE_HEALTH_TEXT =
    defineMessage({
        defaultMessage: 'Open the Health app',
        description:
            'Text for a button that allows the user to open their Health app'
    })

type AppleHealthConnectionResultDialogProps = {
    showSuccess: boolean
    showFailed: boolean
    showError: boolean
    onClose: () => void
}

const AppleHealthConnectionResultDialog: React.FC<
    AppleHealthConnectionResultDialogProps
> = ({ showError, showSuccess, showFailed, onClose }) => {
    const theme = useTheme()
    const intl = useIntl()

    const iconName = useMemo(() => {
        if (showError) {
            return 'triangle-exclamation'
        }
        if (showFailed) {
            return 'circle-xmark'
        }
        return 'circle-check'
    }, [showError, showFailed])

    const headerMessage = useMemo(() => {
        if (showError || showFailed) {
            return APPLE_HEALTH_CONNECT_FLOW_SETUP_FAILED_HEADER
        }
        return APPLE_HEALTH_CONNECT_FLOW_SETUP_COMPLETE_HEADER
    }, [showError, showFailed])

    const subHeaderMessage = useMemo(() => {
        if (showError) {
            return APPLE_HEALTH_CONNECT_FLOW_SETUP_FAILED_UNKNOWN_ERROR_SUBHEADER
        }
        if (showFailed) {
            return APPLE_HEALTH_CONNECT_FLOW_SETUP_FAILED_NO_ACCESS_SUBHEADER
        }
        return APPLE_HEALTH_CONNECT_FLOW_SETUP_COMPLETE_SUBHEADER
    }, [showError, showFailed])

    const buttonMessage = useMemo(() => {
        if (showError) {
            return APPLE_HEALTH_CONNECT_FLOW_SETUP_COMPLETE_CLOSE_BUTTON_TEXT
        }
        if (showFailed) {
            return APPLE_HEALTH_CONNECT_FLOW_SETUP_FAILED_OPEN_APPLE_HEALTH_TEXT
        }
        return APPLE_HEALTH_CONNECT_FLOW_SETUP_COMPLETE_DONE_BUTTON_TEXT
    }, [showError, showFailed])

    const handleOpenHealthClick = useCallback(() => openHealthApp(), [])

    const handleButtonClick = useMemo(() => {
        if (showFailed) {
            return handleOpenHealthClick
        }
        return onClose
    }, [showFailed, handleOpenHealthClick, onClose])

    return (
        <LeafDialog
            data-testid="wearables-native-connect-dialog"
            open={showError || showSuccess || showFailed}
            onClose={onClose}
            fullScreen={true}
        >
            <DialogContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <LeafIcon
                        icon={iconName}
                        sx={{
                            padding: '28px',
                            borderRadius: '50px',
                            fontSize: '100px',
                            mb: 3,
                            backgroundColor: theme.palette.secondary.main,
                            color: theme.palette.secondary.contrastText
                        }}
                    />
                    <CoreTypography variant="h3" mb={1}>
                        {intl.formatMessage(headerMessage)}
                    </CoreTypography>
                    <CoreTypography
                        variant="body1"
                        mb={4}
                        mx={2}
                        textAlign="center"
                    >
                        {intl.formatMessage(subHeaderMessage)}
                    </CoreTypography>
                    <Button
                        onClick={handleButtonClick}
                        variant="contained"
                        sx={{
                            width: '100%'
                        }}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {intl.formatMessage(buttonMessage)}
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogContent>
        </LeafDialog>
    )
}

export default AppleHealthConnectionResultDialog
