import { FC } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'

const MILLIS_IN_SEC = 1000
const SEC_IN_MIN = 60
const MILLIS_IN_MIN = MILLIS_IN_SEC * SEC_IN_MIN
const MIN_IN_HR = 60
const MILLIS_IN_HR = MILLIS_IN_MIN * MIN_IN_HR
const HR_IN_DAY = 24
const MILLIS_IN_DAY = MILLIS_IN_HR * HR_IN_DAY

const LAST_SYNCED_TEXT = defineMessage({
    defaultMessage: 'Last synced',
    description: 'text that indicates the last time the data was synced'
})

// narrow to just the constants we need, avoids redundancy since 'days' and 'day' are both valid units in the base format
type RelativeTimeUnit = Parameters<Intl.RelativeTimeFormat['format']>[1] &
    ('days' | 'hours' | 'minutes' | 'seconds')

type GetUnitFunc = (args: {
    startTimeMillis: number
    endTimeMillis: number
}) => RelativeTimeUnit

type GetDiffInUnitFunc = (args: {
    diffMillis: number
    unit: RelativeTimeUnit
}) => number

type LastSyncedFormatterProps = {
    syncDate: Date
    comparedTo: Date
}

const getUnit: GetUnitFunc = ({ startTimeMillis, endTimeMillis }) => {
    const timeDiff = endTimeMillis - startTimeMillis
    if (timeDiff >= MILLIS_IN_DAY) {
        return 'days'
    }
    if (timeDiff >= MILLIS_IN_HR) {
        return 'hours'
    }
    if (timeDiff >= MILLIS_IN_MIN) {
        return 'minutes'
    }
    return 'seconds'
}

const getDiffInUnit: GetDiffInUnitFunc = ({ diffMillis, unit }) => {
    if (unit === 'days') {
        return Math.floor(diffMillis / MILLIS_IN_DAY)
    }
    if (unit === 'hours') {
        return Math.floor(diffMillis / MILLIS_IN_HR)
    }
    if (unit === 'minutes') {
        return Math.floor(diffMillis / MILLIS_IN_MIN)
    }
    return Math.floor(diffMillis / MILLIS_IN_SEC)
}

const LastSyncedFormatter: FC<LastSyncedFormatterProps> = ({
    syncDate,
    comparedTo = new Date()
}) => {
    const { formatRelativeTime, formatMessage } = useIntl()
    const startTimeMillis = syncDate.valueOf()
    const endTimeMillis = comparedTo.valueOf()

    const unit = getUnit({
        startTimeMillis,
        endTimeMillis
    })

    // having a negative number will lead to the string formatting as "X minutes ago" vs "in X minutes"
    const diffMillis = startTimeMillis - endTimeMillis
    const diffInUnit = getDiffInUnit({
        diffMillis,
        unit
    })

    return (
        <CoreTypography
            data-testid="wearables-last-sync-status"
            variant="body1"
            color="gray"
            gutterBottom
        >
            {formatMessage(LAST_SYNCED_TEXT)}{' '}
            {formatRelativeTime(diffInUnit, unit)}
        </CoreTypography>
    )
}

export default LastSyncedFormatter
