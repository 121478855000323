import { Box, Button, Card, useMediaQuery } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import {
    useAppleHealthAccessStatus,
    useGetUserSourcesQuery,
    useIsIos,
    useOnViewFocus,
    useSaveUserWellBeingSourceMutation,
    useUpdateUserSourceConnectionStatusMutation
} from '../../hooks'
import AppleHealthConnectButton from '../AppleHealthConnectButton/AppleHealthConnectButton'
import AppleHealthConnectionResultDialog from '../AppleHealthConnectionResultDialog/AppleHealthConnectionResultDialog'
import AppleHealthHowToConnectDialog from '../AppleHealthHowToConnectDialog/AppleHealthHowToConnectDialog'
import AppleHealthHowToDisconnectDialog from '../AppleHealthHowToDisconnectDialog/AppleHealthHowToDisconnectDialog'
import { SourceName } from '../../enums'
import ConnectedSource from '../ConnectedSource/ConnectedSource'
import { Avo } from '@thriveglobal/thrive-web-tracking'

const CARD_HEADING = defineMessage({
    defaultMessage: 'Apple Health',
    description:
        'The heading for the card where users can connect more well-being apps'
})

const CARD_DESCRIPTION_WEB = defineMessage({
    defaultMessage: 'Connect Apple Health using the Thrive Global mobile app.',
    description: 'The web view description for how to connect to apple health'
})

const CARD_DESCRIPTION_IOS = defineMessage({
    defaultMessage:
        'Select connect to get started connecting your Apple Health.',
    description:
        'The mobile view description for how to connect to apple health'
})

const DISCONNECT_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Turn off Apple Health',
    description:
        'Label for a button that shows users how to disconnect Apple Health'
})

const VIEW_ON_IOS_TO_UPDATE_PROMPT = defineMessage({
    defaultMessage:
        'To refresh Apple Health data, open the Thrive app on your phone',
    description:
        'text prompting users to open the mobile app to sync their data'
})

type AppleHealthCardProps = {
    onCloseParentModal?: () => void
    onShowHowToConnect?: () => void
    onShowHowToDisconnect?: () => void
    onShowPrivacyModal?: () => void
}

const AppleHealthCard: React.FC<AppleHealthCardProps> = ({
    onCloseParentModal,
    onShowHowToDisconnect,
    onShowHowToConnect,
    onShowPrivacyModal = () => null
}) => {
    const [showInfoDialog, setShowInfoDialog] = useState(false)
    const [showHowToConnect, setShowHowToConnect] = useState(false)
    const [showHowToDisconnect, setShowHowToDisconnect] = useState(false)
    const [hasRequestedAccess, setHasRequestedAccess] = useState(false)

    const theme = useTheme()
    const intl = useIntl()
    const isIos = useIsIos()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [saveUserWellBeingSource] = useSaveUserWellBeingSourceMutation()
    const [updateUserSourceConnectionStatus] =
        useUpdateUserSourceConnectionStatusMutation()
    const { data: userSources, refetch: refetchUserSources } =
        useGetUserSourcesQuery()
    // Refetch the user sources when the view is focused
    useOnViewFocus(refetchUserSources)
    const {
        hasGrantedSleepAccess,
        hasGrantedStepsAccess,
        hasDeniedAccess,
        refreshAccessStatus,
        accessError
    } = useAppleHealthAccessStatus()
    const { appleHealthPromptComplete, appleHealthPromptError } =
        useAppSelector((state) => state.hybrid)

    const handleRequestedAccess = useCallback(() => {
        setHasRequestedAccess(true)

        Avo.promptSelected({
            activityType: 'connect_device_selected',
            contentFormatType: null,
            contentId: null,
            contentSubtype: null,
            contentTitle: null,
            contentType: null,
            contentUrl: null,
            displayText: CARD_DESCRIPTION_WEB.description,
            featureType: 'challenge',
            isOnboarding: false,
            resetId: null,
            resetName: null,
            checkInDate: null,
            contentSource: 'thrive',
            isAutomaticCheckin: false,
            microstepBody: null,
            microstepId: null,
            microstepName: null,
            notificationCount: null,
            tabName: null,
            tstPlatform: null,
            userId_: '',
            nudgeType: null
        })
    }, [])

    const handleCloseParentDialog = useCallback(() => {
        !!onCloseParentModal && onCloseParentModal()
    }, [onCloseParentModal])

    const handleCloseInfoDialog = useCallback(() => {
        setShowInfoDialog(false)
        handleCloseParentDialog()
    }, [handleCloseParentDialog])

    const handleShowHowToConnect = useCallback(() => {
        if (onShowHowToConnect) {
            onShowHowToConnect()
        } else {
            setShowHowToConnect(true)
        }

        Avo.promptSelected({
            activityType: 'connect_device_selected',
            contentFormatType: null,
            contentId: null,
            contentSubtype: null,
            contentTitle: null,
            contentType: null,
            contentUrl: null,
            displayText: CARD_DESCRIPTION_WEB.defaultMessage,
            featureType: 'challenge',
            isOnboarding: false,
            resetId: null,
            resetName: null,
            checkInDate: null,
            contentSource: 'thrive',
            isAutomaticCheckin: false,
            microstepBody: null,
            microstepId: null,
            microstepName: null,
            notificationCount: null,
            tabName: null,
            tstPlatform: null,
            userId_: '',
            nudgeType: null
        })
    }, [onShowHowToConnect])

    const handleCloseHowToConnect = useCallback(
        () => setShowHowToConnect(false),
        []
    )

    const handleShowHowToDisconnect = useCallback(() => {
        if (onShowHowToDisconnect) {
            onShowHowToDisconnect()
        } else {
            setShowHowToDisconnect(true)
        }

        Avo.promptSelected({
            activityType: 'disconnect_device_selected',
            contentFormatType: null,
            contentId: null,
            contentSubtype: null,
            contentTitle: null,
            contentType: null,
            contentUrl: null,
            displayText: DISCONNECT_BUTTON_TEXT.defaultMessage,
            featureType: 'challenge',
            isOnboarding: false,
            resetId: null,
            resetName: null,
            checkInDate: null,
            contentSource: 'thrive',
            isAutomaticCheckin: false,
            microstepBody: null,
            microstepId: null,
            microstepName: null,
            notificationCount: null,
            tabName: null,
            tstPlatform: null,
            userId_: '',
            nudgeType: null
        })
    }, [onShowHowToDisconnect])

    const handleCloseHowToDisconnect = useCallback(
        () => setShowHowToDisconnect(false),
        []
    )

    const hasEnabledAppleHealthOnDevice = useMemo(
        () => !!hasGrantedSleepAccess || !!hasGrantedStepsAccess,
        [hasGrantedSleepAccess, hasGrantedStepsAccess]
    )

    const cannotConnect = useMemo(
        () => !isIos || appleHealthPromptError || accessError,
        [isIos, appleHealthPromptError, accessError]
    )

    const shouldShowInfoDialog = useMemo(
        () => showInfoDialog && hasRequestedAccess,
        [showInfoDialog, hasRequestedAccess]
    )

    const shouldShowSuccessDialog = useMemo(
        () => shouldShowInfoDialog && hasEnabledAppleHealthOnDevice,
        [shouldShowInfoDialog, hasEnabledAppleHealthOnDevice]
    )

    const shouldShowErrorDialog = useMemo(
        () => shouldShowInfoDialog && cannotConnect,
        [shouldShowInfoDialog, cannotConnect]
    )

    const shouldShowFailedDialog = useMemo(
        () => shouldShowInfoDialog && !!hasDeniedAccess,
        [shouldShowInfoDialog, hasDeniedAccess]
    )

    const appleHealthUserSource = useMemo(() => {
        return userSources?.wearables.users.sources.directSources.find(
            (s: { sourceName: string }) =>
                s.sourceName === SourceName.AppleHealth
        )
    }, [userSources])

    const isAppleHealthUserSourceConnected = useMemo(
        () => appleHealthUserSource?.connected || false,
        [appleHealthUserSource]
    )

    const shouldDisconnectAppleHealthUserSource = useMemo(
        () =>
            isAppleHealthUserSourceConnected &&
            (!!hasDeniedAccess || !hasEnabledAppleHealthOnDevice),
        [
            isAppleHealthUserSourceConnected,
            hasDeniedAccess,
            hasEnabledAppleHealthOnDevice
        ]
    )

    const shouldReconnectAppleHealthUserSource = useMemo(
        () =>
            hasEnabledAppleHealthOnDevice && !isAppleHealthUserSourceConnected,
        [isAppleHealthUserSourceConnected, hasEnabledAppleHealthOnDevice]
    )

    // On iOS, rely on the device access as primary source of truth. Otherwise, rely on user sources response
    const showAppleHealthAsConnectedOnCard = useMemo(() => {
        if (isIos) {
            return hasEnabledAppleHealthOnDevice
        } else {
            return isAppleHealthUserSourceConnected
        }
    }, [isIos, hasEnabledAppleHealthOnDevice, isAppleHealthUserSourceConnected])

    const updateUserSourceConnectionStatusIfRequired = useCallback(() => {
        if (isIos && appleHealthUserSource) {
            if (shouldDisconnectAppleHealthUserSource) {
                updateUserSourceConnectionStatus({
                    variables: {
                        sourceId: appleHealthUserSource.sourceId,
                        connected: false
                    }
                })

                Avo.promptSelected({
                    activityType: 'wellbeing_app_setup_completed',
                    contentFormatType: null,
                    contentId: null,
                    contentSubtype: null,
                    contentTitle: null,
                    contentType: null,
                    contentUrl: null,
                    displayText: CARD_HEADING.defaultMessage,
                    featureType: 'challenge',
                    isOnboarding: false,
                    resetId: null,
                    resetName: null,
                    checkInDate: null,
                    contentSource: 'thrive',
                    isAutomaticCheckin: false,
                    microstepBody: null,
                    microstepId: null,
                    microstepName: null,
                    notificationCount: null,
                    tabName: null,
                    tstPlatform: null,
                    userId_: '',
                    nudgeType: null
                })
            } else if (shouldReconnectAppleHealthUserSource) {
                updateUserSourceConnectionStatus({
                    variables: {
                        sourceId: appleHealthUserSource.sourceId,
                        connected: true
                    }
                })

                Avo.promptSelected({
                    activityType: 'wellbeing_app_setup_completed',
                    contentFormatType: null,
                    contentId: null,
                    contentSubtype: null,
                    contentTitle: null,
                    contentType: null,
                    contentUrl: null,
                    displayText: CARD_HEADING.defaultMessage,
                    featureType: 'challenge',
                    isOnboarding: false,
                    resetId: null,
                    resetName: null,
                    checkInDate: null,
                    contentSource: 'thrive',
                    isAutomaticCheckin: false,
                    microstepBody: null,
                    microstepId: null,
                    microstepName: null,
                    notificationCount: null,
                    tabName: null,
                    tstPlatform: null,
                    userId_: '',
                    nudgeType: null
                })
            }
        }
    }, [
        isIos,
        appleHealthUserSource,
        shouldDisconnectAppleHealthUserSource,
        shouldReconnectAppleHealthUserSource,
        updateUserSourceConnectionStatus
    ])

    useEffect(() => {
        if (!appleHealthUserSource && shouldShowSuccessDialog) {
            saveUserWellBeingSource({
                variables: {
                    source: {
                        sourceName: SourceName.AppleHealth,
                        connected: true
                    }
                }
            })

            Avo.promptSelected({
                activityType: 'wellbeing_app_setup_completed',
                contentFormatType: null,
                contentId: null,
                contentSubtype: null,
                contentTitle: null,
                contentType: null,
                contentUrl: null,
                displayText: CARD_HEADING.defaultMessage,
                featureType: 'challenge',
                isOnboarding: false,
                resetId: null,
                resetName: null,
                checkInDate: null,
                contentSource: 'thrive',
                isAutomaticCheckin: false,
                microstepBody: null,
                microstepId: null,
                microstepName: null,
                notificationCount: null,
                tabName: null,
                tstPlatform: null,
                userId_: '',
                nudgeType: null
            })
        } else {
            updateUserSourceConnectionStatusIfRequired()
        }
    }, [
        appleHealthUserSource,
        shouldShowSuccessDialog,
        saveUserWellBeingSource,
        updateUserSourceConnectionStatusIfRequired
    ])

    useEffect(() => {
        if (appleHealthPromptComplete || appleHealthPromptError) {
            refreshAccessStatus()
            setShowInfoDialog(true)
        }
    }, [appleHealthPromptComplete, appleHealthPromptError, refreshAccessStatus])

    const cardDescription = isIos ? CARD_DESCRIPTION_IOS : CARD_DESCRIPTION_WEB

    return (
        <Card
            variant="outlined"
            sx={{
                padding: isSmallScreen ? 2 : 3
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: showAppleHealthAsConnectedOnCard ? 1 : 3,
                    flexDirection: showAppleHealthAsConnectedOnCard
                        ? 'column'
                        : 'row',
                    justifyContent: 'space-between',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column'
                    }
                }}
            >
                <Box sx={{ flexShrink: 20 }}>
                    <CoreTypography variant="h5" gutterBottom>
                        {intl.formatMessage(CARD_HEADING)}
                    </CoreTypography>
                    {!showAppleHealthAsConnectedOnCard && (
                        <CoreTypography variant="body1">
                            {intl.formatMessage(cardDescription)}
                        </CoreTypography>
                    )}
                </Box>
                {showAppleHealthAsConnectedOnCard ? (
                    <Box
                        display="flex"
                        flexDirection={isSmallScreen ? 'column' : 'row'}
                        justifyContent="space-between"
                        gap={2}
                        alignItems={isSmallScreen ? 'none' : 'center'}
                    >
                        <ConnectedSource
                            sourceName={SourceName.AppleHealth}
                            showSourceName={false}
                            lastSynced={appleHealthUserSource?.lastSynced}
                        />
                        <Box flexShrink={0}>
                            <Button
                                fullWidth={isSmallScreen}
                                variant="outlined"
                                onClick={handleShowHowToDisconnect}
                            >
                                <LeafIcon
                                    icon={'link-simple-slash'}
                                    fontSize={'small'}
                                    sx={{ marginRight: '8px' }}
                                />
                                <CoreTypography customVariant="buttonNormal">
                                    {intl.formatMessage(DISCONNECT_BUTTON_TEXT)}
                                </CoreTypography>
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <AppleHealthConnectButton
                        onRequestedAccess={handleRequestedAccess}
                        onShowHowToConnect={handleShowHowToConnect}
                    />
                )}
                {showAppleHealthAsConnectedOnCard && !isIos && (
                    <CoreTypography variant="body1" display="flex" gap={0.5}>
                        <LeafIcon icon={'circle-info'} />
                        {intl.formatMessage(VIEW_ON_IOS_TO_UPDATE_PROMPT)}
                    </CoreTypography>
                )}
            </Box>
            <AppleHealthConnectionResultDialog
                showSuccess={shouldShowSuccessDialog}
                showFailed={shouldShowFailedDialog}
                showError={shouldShowErrorDialog}
                onClose={handleCloseInfoDialog}
            />
            <AppleHealthHowToConnectDialog
                open={showHowToConnect}
                onClose={handleCloseHowToConnect}
                onShowPrivacyInfo={onShowPrivacyModal}
            />
            <AppleHealthHowToDisconnectDialog
                open={showHowToDisconnect}
                onClose={handleCloseHowToDisconnect}
                onShowPrivacyInfo={onShowPrivacyModal}
            />
        </Card>
    )
}

export default AppleHealthCard
