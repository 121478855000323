import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getAppleHealthAccessStatus } from '../../utils'
import {
    AppleHealthAccessLevel,
    AppleHealthPermissionType
} from '../../enums/appleHealth'
import { useIsIos } from '../useIsIos'
import { useOnViewFocus } from '../useOnViewFocus'

type UseAppleHealthAccessStatusReturn =
    | {
          hasGrantedStepsAccess: undefined
          hasGrantedSleepAccess: undefined
          hasDeniedAccess: undefined
          accessError: true
          loadingAccess: false
          refreshAccessStatus: () => void
      }
    | {
          hasGrantedStepsAccess: undefined
          hasGrantedSleepAccess: undefined
          hasDeniedAccess: undefined
          accessError: false
          loadingAccess: true
          refreshAccessStatus: () => void
      }
    | {
          hasGrantedStepsAccess: false
          hasGrantedSleepAccess: false
          hasDeniedAccess: true
          accessError: false
          loadingAccess: false
          refreshAccessStatus: () => void
      }
    | {
          hasGrantedStepsAccess: boolean
          hasGrantedSleepAccess: boolean
          hasDeniedAccess: false
          accessError: false
          loadingAccess: false
          refreshAccessStatus: () => void
      }

const ACCESS_REQUEST_TIMEOUT_MS = 5000

export const useAppleHealthAccessStatus =
    (): UseAppleHealthAccessStatusReturn => {
        const isIos = useIsIos()

        const { appleHealthAuthStatus, appleHealthAuthStatusError } =
            useAppSelector((state) => state.hybrid)

        const [
            hasPerformedInitialAccessPull,
            setHasPerformedInitialAccessPull
        ] = useState(false)
        const [hasAccessRequestTimedOut, setHasAccessRequestTimedOut] =
            useState(false)

        const accessTimeoutRef = useRef<NodeJS.Timeout | null>(null)

        const pullLatestAccessStatus = useCallback(() => {
            if (!isIos) return
            getAppleHealthAccessStatus([
                AppleHealthPermissionType.SLEEP,
                AppleHealthPermissionType.STEPS
            ])
        }, [isIos])

        useEffect(() => {
            if (isIos && !hasPerformedInitialAccessPull) {
                setHasPerformedInitialAccessPull(true)
                pullLatestAccessStatus()
                accessTimeoutRef.current = setTimeout(() => {
                    setHasAccessRequestTimedOut(true)
                }, ACCESS_REQUEST_TIMEOUT_MS)
            }
        }, [pullLatestAccessStatus, hasPerformedInitialAccessPull, isIos])

        const accessPermissions = useMemo(() => {
            if (appleHealthAuthStatus == null || appleHealthAuthStatusError) {
                return undefined
            }
            return {
                stepsAccess: appleHealthAuthStatus?.find(
                    (authStatusItem) =>
                        authStatusItem.permission ===
                        AppleHealthPermissionType.STEPS
                )?.value,
                sleepAccess: appleHealthAuthStatus?.find(
                    (authStatusItem) =>
                        authStatusItem.permission ===
                        AppleHealthPermissionType.SLEEP
                )?.value
            }
        }, [appleHealthAuthStatus, appleHealthAuthStatusError])

        const loadingAccess = useMemo(
            () => accessPermissions == null,
            [accessPermissions]
        )

        useEffect(() => {
            if (!loadingAccess && accessTimeoutRef.current != null) {
                clearTimeout(accessTimeoutRef.current)
                accessTimeoutRef.current = null
                setHasAccessRequestTimedOut(false)
            }
        }, [loadingAccess])

        // Re-fetches access status when the app regains focus
        useOnViewFocus(pullLatestAccessStatus)

        if (!isIos || appleHealthAuthStatusError || hasAccessRequestTimedOut) {
            return {
                hasGrantedSleepAccess: undefined,
                hasGrantedStepsAccess: undefined,
                hasDeniedAccess: undefined,
                accessError: true,
                loadingAccess: false,
                refreshAccessStatus: pullLatestAccessStatus
            }
        }

        if (loadingAccess) {
            return {
                hasGrantedSleepAccess: undefined,
                hasGrantedStepsAccess: undefined,
                hasDeniedAccess: undefined,
                accessError: false,
                loadingAccess: true,
                refreshAccessStatus: () => {}
            }
        }

        if (
            accessPermissions?.stepsAccess ===
                AppleHealthAccessLevel.SHARING_DENIED &&
            accessPermissions.sleepAccess ===
                AppleHealthAccessLevel.SHARING_DENIED
        ) {
            return {
                hasGrantedStepsAccess: false,
                hasGrantedSleepAccess: false,
                hasDeniedAccess: true,
                accessError: false,
                loadingAccess: false,
                refreshAccessStatus: pullLatestAccessStatus
            }
        }

        return {
            hasGrantedStepsAccess:
                accessPermissions?.stepsAccess ===
                AppleHealthAccessLevel.SHARING_AUTHORIZED,
            hasGrantedSleepAccess:
                accessPermissions?.sleepAccess ===
                AppleHealthAccessLevel.SHARING_AUTHORIZED,
            hasDeniedAccess: false,
            accessError: false,
            loadingAccess: false,
            refreshAccessStatus: pullLatestAccessStatus
        }
    }
