import { Box, useTheme } from '@mui/material'
import { BRANDS } from '../ConnectedSource/ConnectedSource'
import { SourceName } from '../../enums'

const HumanApiSupportedBrands = () => {
    const theme = useTheme()
    const excludedBrands: Set<string> = new Set([
        SourceName.AppleHealth,
        SourceName.Oura
    ])
    const supportedBrands = Object.entries(BRANDS)
        .filter(([sourceName]) => !excludedBrands.has(sourceName))
        .map(([_, source]) => source)

    return (
        <Box
            borderRadius={1}
            padding={3}
            display="flex"
            flexWrap="wrap"
            gap={5}
            alignItems="center"
            justifyContent="space-around"
            sx={{
                backgroundColor: theme.palette.grey[100]
            }}
        >
            {supportedBrands.map(({ src, alt }) => (
                <Box key={alt}>
                    <img src={src} alt={alt} />
                </Box>
            ))}
            <Box flex={1}></Box>
        </Box>
    )
}

export default HumanApiSupportedBrands
