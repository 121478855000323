import * as HumanConnect from 'humanapi-connect-client'

import { UPDATE_ACTIVITY_SUMMARY } from '../../graphql/mutations/wearables'
import { GET_HAPI_TOKEN } from '../../graphql/queries/wearables'
import {
    getApolloClient,
    captureException
} from '@thriveglobal/thrive-web-core'
import { HumanApiConnectSessionResult, IHumanApiClient } from './humanApi.types'

const HumanApiClient = (): IHumanApiClient => {
    const apolloClient = getApolloClient()

    return {
        getHapiToken: ({ external_id, thrive_user_id }) => {
            return apolloClient.query({
                query: GET_HAPI_TOKEN,
                fetchPolicy: 'network-only',
                variables: {
                    email: external_id,
                    thriveUserId: thrive_user_id
                }
            })
        },
        updateActivitySummary: () => {
            const currentDate = new Date()
            return apolloClient.mutate({
                mutation: UPDATE_ACTIVITY_SUMMARY,
                variables: {
                    localTimestamp: currentDate.toISOString(),
                    localUTCTimezoneOffsetInMinutes:
                        currentDate.getTimezoneOffset()
                }
            })
        },
        setupConnect: ({
            closeCallback = (_response) => {
                // exposed for override
            },
            connectCallback = (_response) => {
                // exposed for override
            },
            disconnectCallback = (_response) => {
                // exposed for override
            },
            errorCallback = (_response) => {
                // exposed for override
            }
        }) => {
            // this is necessary b/c human API attaches listeners to behind-the-scenes components that
            // need to exist at init time. Would be great if there were callbacks/postMessages
            try {
                HumanConnect.on(
                    'close',
                    (response: HumanApiConnectSessionResult) => {
                        closeCallback(response)
                        return response
                    }
                )
                HumanConnect.on(
                    'connect',
                    (response: HumanApiConnectSessionResult) => {
                        connectCallback(response)
                        return response
                    }
                )
                HumanConnect.on(
                    'disconnect',
                    (response: HumanApiConnectSessionResult) => {
                        disconnectCallback(response)
                        return response
                    }
                )
                HumanConnect.on(
                    'error',
                    (response: HumanApiConnectSessionResult) => {
                        errorCallback(response)
                        return response
                    }
                )
            } catch (e) {
                if (e instanceof Error) {
                    captureException(e, {
                        message: 'error assigning HAPI event listeners'
                    })
                }
            }
            return HumanConnect
        }
    }
}

const humanApiClient = HumanApiClient()

export default humanApiClient
