import { Box, CircularProgress, Fade } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { format } from 'date-fns'
import { useGetStepCountQuery } from '../../hooks'

const messages = defineMessages({
    stepsToday: {
        defaultMessage: 'steps today',
        description:
            'description text for the total number of steps a user has taken today, the value of which is shown to the left'
    }
})

type StepsProgressProps = {
    refetch: () => void
}

const StepsProgress: React.FC<StepsProgressProps> = ({ refetch }) => {
    const theme = useTheme()
    const intl = useIntl()
    const { data: activityData, loading: activityLoading } =
        useGetStepCountQuery({
            variables: {
                date: format(new Date(), 'yyyy-MM-dd')
            },
            onCompleted: () => {
                refetch()
            }
        })

    const stepCount = useMemo(
        () => activityData?.wearables.activity.getStepCount || 0,
        [activityData]
    )

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                pt: theme.spacing(1),
                minHeight: theme.spacing(8.5)
            }}
            data-testid="steps-progress"
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: 0
                }}
            >
                <Fade in={activityLoading} mountOnEnter unmountOnExit>
                    <span>
                        <CircularProgress
                            color={'accent'}
                            sx={{ ml: theme.spacing(1.25) }}
                            data-testid="steps-progress-loader"
                        />
                    </span>
                </Fade>
            </Box>
            <Fade in={!activityLoading} mountOnEnter unmountOnExit>
                <Box>
                    <CoreTypography variant="h2">
                        {intl.formatNumber(stepCount)}
                    </CoreTypography>
                    <CoreTypography
                        variant="overline"
                        sx={{
                            color: theme.palette.text.primary
                        }}
                    >
                        {intl.formatMessage(messages.stepsToday)}
                    </CoreTypography>
                </Box>
            </Fade>
        </Box>
    )
}

export default StepsProgress
