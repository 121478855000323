import { DialogContent, DialogContentProps } from '@mui/material'
import React, { useRef } from 'react'

/**
 * This component is a wrapper around the DialogContent component. It hoists the height of the
 * content to it's initial height. This is needed because the LeafDialog component
 * does not have a min-height property. Due to this, the height changes when the content
 * changes.
 **/
const StaticDialogContent: React.FC<DialogContentProps> = ({
    children,
    sx,
    ...restProps
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    return (
        <DialogContent
            ref={containerRef}
            sx={{
                height: containerRef.current?.clientHeight,
                ...sx
            }}
            {...restProps}
        >
            {children}
        </DialogContent>
    )
}

export default StaticDialogContent
