import { Box, Card, Collapse, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { ManualActivity } from '../../hooks/useManualActivities/useManualActivities'
import { POINTS_MESSAGE } from '../../utils/intlConstants'

const ACTIVITY_CARD_TITLE = defineMessage({
    defaultMessage: 'Activity points',
    description: 'Activity count stat title'
})

const INVALID_ACTIVITY_TEXT = defineMessage({
    defaultMessage: 'Maximum activity allowed cannot exceed 32000',
    description: 'input adornment for the activity duration in minutes'
})

export interface ActivityCountProps {
    calculatedActivity: number
    invalid: boolean
}

const ActivityCount: React.FC<ActivityCountProps> = ({
    calculatedActivity,
    invalid
}) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Stack gap={1}>
            <Card
                variant="elevation"
                elevation={0}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.grey[100],
                    gap: 1,
                    padding: 2
                }}
            >
                <Stack gap={1}>
                    <Box display="flex" alignItems="center" gap={0.5}>
                        <CoreTypography
                            variant="overline"
                            color={theme.palette.text.primary}
                        >
                            {formatMessage(ACTIVITY_CARD_TITLE)}
                        </CoreTypography>
                    </Box>
                    <Stack
                        gap={1}
                        direction={isMobile ? 'column' : 'row'}
                        alignItems={isMobile ? 'flex-start' : 'center'}
                    >
                        <CoreTypography variant="h3">
                            {formatMessage(POINTS_MESSAGE, {
                                points: calculatedActivity
                            })}
                        </CoreTypography>
                    </Stack>
                </Stack>
            </Card>
            <Collapse in={invalid} mountOnEnter unmountOnExit>
                <CoreTypography variant="body1" color="error.main">
                    {formatMessage(INVALID_ACTIVITY_TEXT)}
                </CoreTypography>
            </Collapse>
        </Stack>
    )
}

export default memo(ActivityCount)
