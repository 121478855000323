import { format } from '@thriveglobal/thrive-web-core'
import { LeafDatePicker } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { defineMessage, useIntl } from 'react-intl'

const ACTIVITY_DATE_LABEL = defineMessage({
    defaultMessage: 'Activity date',
    description: 'date input label text'
})

const DATE_HELPER_TEXT = defineMessage({
    defaultMessage: 'Undo changes before selecting another date.',
    description: 'helper text for the date picker'
})

type ManualActivityDatePickerProps = {
    activityDate?: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
    startDate: Date
    disabled: boolean
}

const ManualActivityDatePicker: React.FC<ManualActivityDatePickerProps> = ({
    activityDate,
    onChange,
    disabled,
    startDate
}) => {
    const { formatMessage } = useIntl()

    const dateHelperText = useMemo(
        () => (disabled ? formatMessage(DATE_HELPER_TEXT) : undefined),
        [disabled, formatMessage]
    )

    return (
        <LeafDatePicker
            aria-label={formatMessage(ACTIVITY_DATE_LABEL)}
            id="activity-date-picker-input"
            type="date"
            color="primary"
            variant="outlined"
            label={formatMessage(ACTIVITY_DATE_LABEL)}
            value={activityDate}
            onChange={onChange}
            inputProps={{
                max: format(new Date()),
                min: format(startDate)
            }}
            formControlProps={{
                fullWidth: true
            }}
            disabled={disabled}
            helperText={dateHelperText}
        />
    )
}

export default ManualActivityDatePicker
