import { Card, Stack, useMediaQuery, useTheme } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import HumanAPIConnectButton from '../HumanAPIConnectButton/HumanAPIConnectButton'
import { defineMessage, useIntl } from 'react-intl'
import { HumanApiProvider } from '../../providers/HumanApiProvider'
import {
    GetHapiSourceReferenceQuery,
    GetUserSourcesQuery,
    useGetHapiSourceReferenceQuery,
    useGetUserSourcesQuery,
    useSaveUserWellBeingSourceMutation,
    useUpdateUserSourceConnectionStatusMutation
} from '../../graphql/generated/autogenerated'
import { useCallback, useEffect, useMemo } from 'react'
import ConnectedSourcesList from '../ConnectedSource/ConnectedSourcesList'
import HumanApiSupportedBrands from './HumanApiSupportedBrands'
import ManualSync from './ManualSync'

type HumanApiSourceReference =
    GetHapiSourceReferenceQuery['wearables']['humanApi']['sourceReference'][0]
type HumanApiUserSource =
    GetUserSourcesQuery['wearables']['users']['sources']['humanApiSources'][0]

const CARD_HEADING = defineMessage({
    defaultMessage: 'More health and fitness apps',
    description:
        'The heading for the card where users can connect more health and fitness apps'
})

const CARD_DESCRIPTION = defineMessage({
    defaultMessage:
        'Click connect to start adding supported health and fitness apps',
    description:
        'The description for how to connect more health and fitness apps'
})

const CONNECTED_APPS_DESCRIPTION = defineMessage({
    defaultMessage:
        "Your application is syncing properly if it's last synced time is within the designated time frequencies.",
    description: 'Text that helps understand if their data is syncing properly'
})

const MoreWellBeingAppsCard = () => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const { data: userSources, refetch: refetchUserSources } =
        useGetUserSourcesQuery()
    const { data: hapiReferences } = useGetHapiSourceReferenceQuery()
    const [updateUserSourceConnectionStatus] =
        useUpdateUserSourceConnectionStatusMutation()
    const [saveUserSource] = useSaveUserWellBeingSourceMutation()

    const searchParams = useCallback(
        () => new URLSearchParams(window.location.search),
        []
    )

    const isHapiConnectCallback = useMemo(
        () => searchParams().has('result'),
        [searchParams]
    )

    const humanApiUserSources = useMemo(
        () => userSources?.wearables.users.sources.humanApiSources,
        [userSources]
    )

    const humanApiUserSourcesMap = useMemo(() => {
        return humanApiUserSources?.reduce(
            (acc: Record<string, HumanApiUserSource>, nxt) => {
                if (!nxt?.externalSourceId) {
                    return acc
                }

                acc[nxt.externalSourceId] = nxt
                return acc
            },
            {}
        )
    }, [humanApiUserSources])

    const humanApiSourceRefMap = useMemo(() => {
        return hapiReferences?.wearables.humanApi.sourceReference.reduce(
            (acc: Record<string, HumanApiSourceReference>, nxt) => {
                if (!nxt.humanApiSourceId) {
                    return acc
                }

                acc[nxt.humanApiSourceId] = nxt
                return acc
            },
            {}
        )
    }, [hapiReferences])

    const handleHapiConnectSessionResults = useCallback(() => {
        if (isHapiConnectCallback && humanApiUserSourcesMap) {
            const encodedString = searchParams().get('result') || ''
            const encodedData = JSON.parse(encodedString)
            const connectedSources =
                encodedData.sessionResults?.connectedSources || []
            const disconnectedSources =
                encodedData.sessionResults?.disconnectedSources || []

            if (
                connectedSources.length == 0 &&
                disconnectedSources.length == 0
            ) {
                return
            }

            const newSourcePayloads: {
                sourceName: string
                connected: boolean
            }[] = []
            const updateSourcePayloads: {
                sourceId: string
                connected: boolean
            }[] = []

            connectedSources.forEach((source: { id: string }) => {
                const userSource = humanApiUserSourcesMap[source.id]
                if (userSource) {
                    updateSourcePayloads.push({
                        sourceId: userSource.sourceId,
                        connected: true
                    })
                } else if (humanApiSourceRefMap?.[source.id]) {
                    newSourcePayloads.push({
                        sourceName: humanApiSourceRefMap[source.id].sourceName,
                        connected: true
                    })
                }
            })

            disconnectedSources.forEach((source: { id: string }) => {
                const userSource = humanApiUserSourcesMap[source.id]
                if (userSource) {
                    updateSourcePayloads.push({
                        sourceId: userSource.sourceId,
                        connected: false
                    })
                } else if (humanApiSourceRefMap?.[source.id]) {
                    newSourcePayloads.push({
                        sourceName: humanApiSourceRefMap[source.id].sourceName,
                        connected: false
                    })
                }
            })

            updateSourcePayloads.forEach((payload) => {
                updateUserSourceConnectionStatus({
                    variables: payload
                })
            })
            newSourcePayloads.forEach((payload) => {
                saveUserSource({
                    variables: {
                        source: payload
                    }
                })
            })
            refetchUserSources()
        }
    }, [
        searchParams,
        isHapiConnectCallback,
        humanApiSourceRefMap,
        humanApiUserSourcesMap,
        updateUserSourceConnectionStatus,
        saveUserSource,
        refetchUserSources
    ])

    const userHasConnectedSources = useMemo(
        () => (humanApiUserSources || []).filter((s) => s.connected).length > 0,
        [humanApiUserSources]
    )

    useEffect(() => {
        handleHapiConnectSessionResults()
    }, [handleHapiConnectSessionResults])

    return (
        <HumanApiProvider>
            <Card
                variant="outlined"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    padding: isSmallScreen ? 2 : 3
                }}
            >
                <Stack gap={1}>
                    <Stack
                        direction="row"
                        flex={1}
                        justifyContent="space-between"
                        alignItems={!isSmallScreen ? 'center' : undefined}
                        gap={3}
                        sx={{
                            [theme.breakpoints.down('sm')]: {
                                flexDirection: 'column'
                            }
                        }}
                    >
                        <CoreTypography variant="h5" gutterBottom>
                            {formatMessage(CARD_HEADING)}
                        </CoreTypography>
                        {!isSmallScreen && (
                            <HumanAPIConnectButton
                                hasConnectedSources={userHasConnectedSources}
                            />
                        )}
                    </Stack>
                    <CoreTypography variant="body1">
                        {!userHasConnectedSources
                            ? formatMessage(CARD_DESCRIPTION)
                            : formatMessage(CONNECTED_APPS_DESCRIPTION)}
                    </CoreTypography>
                </Stack>
                {userHasConnectedSources && (
                    <>
                        <ManualSync refetchUserSources={refetchUserSources} />
                        <ConnectedSourcesList
                            userSources={humanApiUserSources ?? []}
                        />
                    </>
                )}

                {isSmallScreen && (
                    <HumanAPIConnectButton
                        hasConnectedSources={userHasConnectedSources}
                    />
                )}
                {!userHasConnectedSources && <HumanApiSupportedBrands />}
            </Card>
        </HumanApiProvider>
    )
}

export default MoreWellBeingAppsCard
