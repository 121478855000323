import {
    HybridEventType,
    getBestFitLocale,
    postMessageToWebview
} from '@thriveglobal/thrive-web-core'
import { AppleHealthPermissionType } from '../enums'

export const getAppleHealthAccessStatus = (
    permissionTypes: AppleHealthPermissionType[]
) => {
    postMessageToWebview({
        type: HybridEventType.REQUEST_APPLE_HEALTH_STATUS,
        value: {
            read: permissionTypes,
            write: permissionTypes
        }
    })
}

export const requestAppleHealthAccess = (
    permissionTypes: AppleHealthPermissionType[]
) => {
    postMessageToWebview({
        type: HybridEventType.REQUEST_APPLE_HEALTH_PROMPT,
        value: {
            read: permissionTypes,
            write: permissionTypes
        }
    })
}

type PullAppleHealthData = (dateRange: {
    startDateIso: string
    endDateIso: string
}) => void

export const pullAppleHealthSleepData: PullAppleHealthData = ({
    startDateIso,
    endDateIso
}) => {
    postMessageToWebview({
        type: HybridEventType.REQUEST_APPLE_HEALTH_SLEEP_DATA,
        value: {
            startDate: startDateIso,
            endDate: endDateIso
        }
    })
}

export const pullAppleHealthStepsData: PullAppleHealthData = ({
    startDateIso,
    endDateIso
}) => {
    postMessageToWebview({
        type: HybridEventType.REQUEST_APPLE_HEALTH_STEPS_DATA,
        value: {
            startDate: startDateIso,
            endDate: endDateIso
        }
    })
}

export const openHealthApp = (): void => {
    window.open('x-apple-health://')
}

export const getLocaleForZendesk = () =>
    getBestFitLocale().toLocaleLowerCase().replace('zh-hans', 'zh-ha') // Zendesk doesn't support zh-hans

export const getWearablesFAQLink = () => {
    const locale = getLocaleForZendesk()
    return `https://thriveglobal.zendesk.com/hc/${locale}/sections/7675711687187-Connected-Health-Apps`
}

export const getDataSyncFAQLink = () => {
    const locale = getLocaleForZendesk()
    return `https://thriveglobal.zendesk.com/hc/${locale}/articles/21989180365587-Troubleshooting-Data-Sync-Issues`
}

export * from './appleHealthDialogConstants'
export * from './iframe'
