import { Box, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafExternalLink,
    LeafIcon,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, { PropsWithChildren, useCallback, useState } from 'react'
import { useIntl, defineMessage } from 'react-intl'
import { getDataSyncFAQLink } from '../../utils'
import { useSyncHapiSourcesMutation } from '../../hooks'

const SYNC_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Sync data',
    description: 'Label for a button that shows users how to sync data'
})

const DATA_SYNCED_TEXT = defineMessage({
    defaultMessage: 'Data synced',
    description: 'Label for a button that shows users how to sync data'
})

const HELP_TEXT = defineMessage({
    defaultMessage:
        'Visit our <FAQLink>troubleshooting article</FAQLink> for additional assistance.',
    description:
        'Text with FAQ link that helps users troubleshoot their data syncing issues'
})

const FAQLink: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <LeafExternalLink
            display="inline-flex"
            externalLink={getDataSyncFAQLink()}
            iconFontSize="inherit"
            tabIndex={0}
        >
            {children}
        </LeafExternalLink>
    )
}

const getFAQLink = (chunk: string) => <FAQLink>{chunk}</FAQLink>

type ManualSyncProps = {
    refetchUserSources: () => void
}

const ManualSync: React.FC<ManualSyncProps> = ({ refetchUserSources }) => {
    const { formatMessage } = useIntl()
    const [showDataSynced, setShowDataSynced] = useState(false)
    const [showHelpText, setShowHelpText] = useState(false)

    const [syncSources, { loading }] = useSyncHapiSourcesMutation()

    const handleSync = useCallback(() => {
        if (loading || showDataSynced) return
        syncSources().then(() => {
            // Get up-to-date sources data
            refetchUserSources()

            setShowDataSynced(true)
            setShowHelpText(true)
            setTimeout(() => {
                setShowDataSynced(false)
            }, 5000)
        })
    }, [loading, refetchUserSources, showDataSynced, syncSources])

    return (
        <Stack>
            {!showDataSynced ? (
                <LoadingButton
                    variant="text"
                    color="primary"
                    fixWidth
                    loading={loading}
                    onClick={handleSync}
                    sx={{
                        width: 'fit-content',
                        flexDirection: 'row',
                        flexWrap: 'nowrap'
                    }}
                >
                    <CoreTypography
                        customVariant="buttonNormal"
                        as="div"
                        display="flex"
                        alignItems="center"
                    >
                        <LeafIcon
                            icon={'arrows-rotate'}
                            fontSize={'small'}
                            sx={{ marginRight: 1 }}
                        />
                        {formatMessage(SYNC_BUTTON_TEXT)}
                    </CoreTypography>
                </LoadingButton>
            ) : (
                <Box>
                    <CoreTypography
                        customVariant="buttonNormal"
                        as="div"
                        display="flex"
                        alignItems="center"
                        padding={'6px 8px'}
                        width="fit-content"
                        minHeight="44px"
                    >
                        <LeafIcon
                            icon={'circle-check'}
                            fontSize={'small'}
                            sx={{ marginRight: 1 }}
                        />
                        {formatMessage(DATA_SYNCED_TEXT)}
                    </CoreTypography>
                </Box>
            )}

            <Box>
                {showHelpText &&
                    formatMessage(HELP_TEXT, {
                        FAQLink: getFAQLink
                    } as unknown as Record<string, React.ReactNode>)}
            </Box>
        </Stack>
    )
}

export default ManualSync
