import { Locale, getBestFitLocale } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const STEPS = {
    1: 'STEP_ONE',
    2: 'STEP_TWO',
    3: 'STEP_THREE',
    4: 'STEP_FOUR'
} as const

const INSTRUCTION_TEXTS = defineMessages({
    [STEPS[1]]: {
        defaultMessage: 'Go to settings on your iOS device, and select Health',
        description:
            'Instructions for the first step to disconnect apple health'
    },
    [STEPS[2]]: {
        defaultMessage: 'In Health, select Data Access & Devices',
        description:
            'Instructions for the second step to disconnect apple health'
    },
    [STEPS[3]]: {
        defaultMessage: 'Select “Thrive”',
        description:
            'Instructions for the third step to disconnect apple health'
    },
    [STEPS[4]]: {
        defaultMessage: 'Select “Turn Off All”',
        description:
            'Instructions for the fourth step to disconnect apple health'
    }
})

const IMAGE_ALTS = defineMessages({
    [STEPS[1]]: {
        defaultMessage: 'Illustration of the Apple settings & health icons',
        description: 'Alt text for image'
    },
    [STEPS[2]]: {
        defaultMessage:
            'Illustration of the "Data access & Devices" button in Apple health settings',
        description: 'Alt text for image'
    },
    [STEPS[3]]: {
        defaultMessage:
            'Illustration of the Thrive app button in Apple Health settings',
        description: 'Alt text for image'
    },
    [STEPS[4]]: {
        defaultMessage:
            'Illustration of "Turn off all" button in the Apple Health settings',
        description: 'Alt text for image'
    }
})

const APPLE_HEALTH_SETTINGS_IMAGE_SRC =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/ff0caf17-694b-4c33-784b-3f1cd9fcbf00/cms'

const LOCALE_TO_IMAGE_SRC = {
    [Locale.EN_US]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/851f9f5b-dfd5-4826-6f68-395d44a0b900/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/252b1b72-4367-4855-e90f-cee91ad93f00/cms'
    },
    [Locale.DE_DE]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/51df4c87-2b4e-4490-3f71-559324ca3900/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/052ee5a8-5d9d-43a7-1066-e6f414467500/cms'
    },
    [Locale.ES_419]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/5f79eb04-6989-4cd5-c02d-7a233d7b3a00/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/e0e94a38-9d76-41b2-e441-26db21525e00/cms'
    },
    [Locale.FR_CA]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/08bc403e-4324-4e1b-66e4-5b7aa5b16f00/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/22e0a527-4912-46c5-2438-75d975fecd00/cms'
    },
    [Locale.FR_FR]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/d6e757a4-9891-44e6-6cfa-7917a9b36600/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/06e9c8e3-47dd-4ed6-fd87-a9b840bb7b00/cms'
    },
    [Locale.IT_IT]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/8fe9c667-d111-45de-95b0-999d1bee8f00/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/61db2174-8e29-4ffd-2f10-444b0de21a00/cms'
    },
    [Locale.JA_JP]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/ad9a91c7-815d-4484-596d-c8bd49b2a000/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4b2f6a36-ff01-47b4-dd6c-6c9202308e00/cms'
    },
    [Locale.PT_BR]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/7e92e225-52b0-4951-04f6-937b440e1800/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/e5aeb680-1b21-4e36-dcf0-6b0d5126e900/cms'
    },
    [Locale.ZH_HANS]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/90fc3a3c-37f0-4236-f5ed-a7a276596600/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4c037c88-0766-4004-b41b-e67a5b74ff00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/3ed8f899-02c4-43ee-e60c-1d1f5c7c1b00/cms'
    }
} as const

const AppleHealthHowToDisconnectDialogContent: React.FC = () => {
    const intl = useIntl()
    const locale = useMemo(() => {
        const bestFitLocale = getBestFitLocale() as Locale
        if (
            Object.prototype.hasOwnProperty.call(
                LOCALE_TO_IMAGE_SRC,
                bestFitLocale
            )
        ) {
            return bestFitLocale as keyof typeof LOCALE_TO_IMAGE_SRC
        }
        return Locale.EN_US
    }, [])
    const imageURLs = LOCALE_TO_IMAGE_SRC[locale]

    return (
        <ol
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 24
            }}
        >
            {Object.values(STEPS).map((step) => (
                <li key={step}>
                    <CoreTypography variant="body1" gutterBottom>
                        {intl.formatMessage(INSTRUCTION_TEXTS[step])}
                    </CoreTypography>
                    <img
                        src={
                            step === 'STEP_ONE'
                                ? APPLE_HEALTH_SETTINGS_IMAGE_SRC
                                : imageURLs[step]
                        }
                        alt={intl.formatMessage(IMAGE_ALTS[step])}
                    />
                </li>
            ))}
        </ol>
    )
}

export default AppleHealthHowToDisconnectDialogContent
