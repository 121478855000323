import { useMemo } from 'react'
import { useGetManualActivityOptionsQuery } from '..'
import { ApolloError } from '@apollo/client'

export type ManualActivity = {
    /** UUID of the manual activity */
    id: string
    /** Description of the manual activity */
    description: string
    /** Steps per minute */
    stepsPerMin: number
    /** Indicates whether it is a core stepping activity */
    isCoreStep: boolean
}

export type UseManualActivitiesReturnType =
    | {
          manualActivities: Record<string, ManualActivity>
          loading: false
          error: undefined
      }
    | {
          manualActivities: undefined
          loading: true
          error: undefined
      }
    | {
          manualActivities: undefined
          loading: false
          error: ApolloError
      }

export const useManualActivities = (): UseManualActivitiesReturnType => {
    const { data, loading, error } = useGetManualActivityOptionsQuery()

    return useMemo(() => {
        if (loading) {
            return {
                manualActivities: undefined,
                loading: true,
                error: undefined
            }
        }

        if (error) {
            return {
                manualActivities: undefined,
                loading: false,
                error
            }
        }

        const manualActivities: Record<string, ManualActivity> =
            data?.wearables.activity.getManualActivityOptions.reduce(
                (acc, activity) => ({
                    ...acc,
                    [activity.uuid]: {
                        id: activity.uuid,
                        description: activity.activityDescription,
                        stepsPerMin: activity.stepsPerMin,
                        isCoreStep: activity.isCoreStep
                    }
                }),
                {}
            ) ?? {}

        return {
            manualActivities,
            loading,
            error
        }
    }, [error, loading, data?.wearables.activity.getManualActivityOptions])
}

export default useManualActivities
