import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import { useMemo, useCallback, FC } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { useHumanApiContext } from '../../providers/HumanApiProvider'
import { Avo } from '@thriveglobal/thrive-web-tracking'

const MANAGE_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Manage connections',
    description:
        'The label for a button that allows users to manage existing health apps'
})
const CONNECT_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Connect',
    description:
        'The label for a button that allows users to connect a health app'
})

type HumanAPIConnectButtonProps = {
    hasConnectedSources: boolean
}

const HumanAPIConnectButton: FC<HumanAPIConnectButtonProps> = ({
    hasConnectedSources
}) => {
    const intl = useIntl()
    const { connectReady, connectToken } = useHumanApiContext()

    const isInIframe = useMemo(() => window.self !== window.top, [])

    const navigateToHostedApp = useCallback(() => {
        // Using origin path here to avoid any query params
        const originPath = window.location.origin + window.location.pathname
        const hapiLink = `https://hapi-link.humanapi.co/connect?clientId=${
            process.env.HUMAN_API_CLIENT_ID
        }&token=${connectToken}&callback=${encodeURIComponent(originPath)}`

        if (connectReady) {
            Avo.promptSelected({
                activityType: 'connect_device_selected',
                contentFormatType: null,
                contentId: null,
                contentSubtype: null,
                contentTitle: null,
                contentType: null,
                contentUrl: null,
                displayText: CONNECT_BUTTON_TEXT.defaultMessage,
                featureType: 'challenge',
                isOnboarding: false,
                resetId: null,
                resetName: null,
                checkInDate: null,
                contentSource: 'thrive',
                isAutomaticCheckin: false,
                microstepBody: null,
                microstepId: null,
                microstepName: null,
                notificationCount: null,
                tabName: null,
                tstPlatform: null,
                userId_: '',
                nudgeType: null
            })

            if (isInIframe) {
                window.open(hapiLink, '_blank')
            } else {
                window.location.replace(hapiLink)
            }
        }
    }, [connectToken, connectReady, isInIframe])

    return (
        <LoadingButton
            variant="outlined"
            size="medium"
            onClick={navigateToHostedApp}
            disabled={!connectReady}
            loading={!connectReady}
            fixWidth
            sx={{ height: 'fit-content' }}
        >
            <CoreTypography customVariant="buttonNormal">
                {!hasConnectedSources &&
                    intl.formatMessage(CONNECT_BUTTON_TEXT)}
                {hasConnectedSources && intl.formatMessage(MANAGE_BUTTON_TEXT)}
            </CoreTypography>
        </LoadingButton>
    )
}

export default HumanAPIConnectButton
