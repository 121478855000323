import { Box } from '@mui/material'
import { Locale, getBestFitLocale } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const STEPS = {
    1: 'STEP_ONE',
    2: 'STEP_TWO',
    3: 'STEP_THREE',
    4: 'STEP_FOUR'
} as const

const INSTRUCTION_TEXTS = defineMessages({
    [STEPS[1]]: {
        defaultMessage:
            'Open the Thrive Global app or download the Thrive Global app from the App Store on your mobile device.',
        description: 'Instructions for the first step to connect apple health'
    },
    [STEPS[2]]: {
        defaultMessage:
            'In the Thrive Global app, go to your profile and the “Health & Fitness apps” tab',
        description: 'Instructions for the second step to connect apple health'
    },
    [STEPS[3]]: {
        defaultMessage: 'Select “Connect Apple Health”',
        description: 'Instructions for the third step to connect apple health'
    },
    [STEPS[4]]: {
        defaultMessage: 'Allow Thrive to access and update Health data',
        description: 'Instructions for the fourth step to connect apple health'
    }
})

const IMAGE_ALTS = defineMessages({
    [STEPS[1]]: {
        defaultMessage: 'The thrive global app icon',
        description: 'Alt text for image'
    },
    [STEPS[2]]: {
        defaultMessage: 'Illustration of the Health & Fitness apps tab',
        description: 'Alt text for image'
    },
    [STEPS[3]]: {
        defaultMessage: 'Illustration of the connect to Apple Health button”',
        description: 'Alt text for image'
    },
    [STEPS[4]]: {
        defaultMessage:
            'Illustration of the button in Apple health settings that turns on access for the Thrive global app',
        description: 'Alt text for image'
    }
})

const THRIVE_APP_ICON_SRC =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/dc6c83b8-db10-4acf-663e-97684bf9bd00/cms'

const LOCALE_TO_IMAGE_SRC = {
    [Locale.EN_US]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/06327bc9-9446-4195-3ba1-527d8baf7c00/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4617a3ba-a0ac-416d-2a3f-c86862ea6700/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/b72642d2-f1fa-4de9-3d0a-10e3bd20ee00/cms'
    },
    [Locale.DE_DE]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/bacd56b4-2e2a-40a8-6eb0-ab7392080b00/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/1bbc9065-cd4e-4afc-6c43-19cf13d98f00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/011d0b49-09f5-4c16-6ebe-cd0c35434a00/cms'
    },
    [Locale.ES_419]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/2cddf014-b52e-4c2c-bf24-be6cbcee2700/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/ef86b490-7a7d-42ed-a8d1-b02167cb8600/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/6f020c7a-6873-410e-6596-31fbbc47b100/cms'
    },
    [Locale.FR_CA]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/ab72d73e-f80a-4b34-b0eb-f9dc73067f00/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/40311e0b-b714-4d50-c4e5-7d7673b47d00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/a86dbd85-2fc1-4f42-cdfd-cec46970e300/cms'
    },
    [Locale.FR_FR]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/f2945dad-81d8-4016-d14a-919e76db0100/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/62a59af6-3c87-4dd6-bc56-cc97fd5e6100/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/1a23f70b-cf6c-4f1c-9846-ecab7ab52700/cms'
    },
    [Locale.IT_IT]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/abf0b2e8-f735-49cb-6327-24ef3e2a1600/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/fd8ccf25-9d43-4fb7-1ac1-de44e3c24600/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/2bcfa7b2-ce22-4f02-42d7-cb856fd84f00/cms'
    },
    [Locale.JA_JP]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/8ee52216-e689-43b9-0932-9d2b97aacd00/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/ee137fd4-b8cf-4a71-da5c-93b16e584700/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/40e3667f-6d2d-437c-d7c2-b08717976e00/cms'
    },
    [Locale.PT_BR]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/9811e1b3-bd82-415a-34d0-eabdd6fd1100/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/22fe39ad-5ac9-4113-c62d-e7c5a7361300/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/edb9fa3b-a659-4599-b93a-eb956bc0cb00/cms'
    },
    [Locale.ZH_HANS]: {
        [STEPS[2]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/17594a63-13b4-44f3-7008-3a8bfa834b00/cms',
        [STEPS[3]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c1829178-9d29-4e28-8b1a-4a9262237b00/cms',
        [STEPS[4]]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/771a34a2-d043-480d-1eac-6def97e5e500/cms'
    }
} as const

const AppleHealthHowToConnectDialogContent: React.FC = () => {
    const intl = useIntl()
    const locale = useMemo(() => {
        const bestFitLocale = getBestFitLocale() as Locale
        if (
            Object.prototype.hasOwnProperty.call(
                LOCALE_TO_IMAGE_SRC,
                bestFitLocale
            )
        ) {
            return bestFitLocale as keyof typeof LOCALE_TO_IMAGE_SRC
        }
        return Locale.EN_US
    }, [])
    const imageURLs = LOCALE_TO_IMAGE_SRC[locale]

    return (
        <Box sx={{ overflowX: 'clip' }}>
            <ol
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 24
                }}
            >
                {Object.values(STEPS).map((step) => (
                    <li key={step}>
                        <CoreTypography variant="body1" gutterBottom>
                            {intl.formatMessage(INSTRUCTION_TEXTS[step])}
                        </CoreTypography>
                        <img
                            src={
                                step === 'STEP_ONE'
                                    ? THRIVE_APP_ICON_SRC
                                    : imageURLs[step]
                            }
                            alt={intl.formatMessage(IMAGE_ALTS[step])}
                        />
                    </li>
                ))}
            </ol>
        </Box>
    )
}

export default AppleHealthHowToConnectDialogContent
