import { Card } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { POINTS_MESSAGE } from '../../utils/intlConstants'

const TOTAL_ACTIVITY_CARD_TITLE = defineMessage({
    defaultMessage: 'Total activity points',
    description: 'Total activity count stat title'
})

type TotalActivityCountProps = {
    activityTotal: number
}

const TotalActivityCount: React.FC<TotalActivityCountProps> = ({
    activityTotal
}) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()
    return (
        <Card
            elevation={0}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.grey[100],
                gap: 1,
                padding: 2
            }}
        >
            <CoreTypography
                variant="overline"
                color={theme.palette.text.primary}
            >
                {formatMessage(TOTAL_ACTIVITY_CARD_TITLE)}
            </CoreTypography>
            <CoreTypography variant="h5" color={theme.palette.text.primary}>
                {formatMessage(POINTS_MESSAGE, {
                    points: activityTotal
                })}
            </CoreTypography>
        </Card>
    )
}

export default TotalActivityCount
