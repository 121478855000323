import { Card, Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import ManualActivityRow from './ManualActivityRow'
import { POINTS_MESSAGE } from '../../utils/intlConstants'

const MANUAL_ACTIVITY_CARD_TITLE = defineMessage({
    defaultMessage: 'Manually added',
    description: 'Manually added activity card title'
})

type ManualActivitiesCardProps = {
    manualActivityTotal: number
    manualActivities: {
        durationMins: number
        steps: number
        label: string
        id: string
        date: string
    }[]
    activitiesToDelete: Set<string>
    setActivitiesToDelete: React.Dispatch<React.SetStateAction<Set<string>>>
}

const ManualActivitiesCard: React.FC<ManualActivitiesCardProps> = ({
    manualActivityTotal,
    manualActivities,
    activitiesToDelete,
    setActivitiesToDelete
}) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    const handleToggleDelete = useCallback(
        (activityId: string) => {
            const copy = new Set(activitiesToDelete)
            if (activitiesToDelete.has(activityId)) {
                copy.delete(activityId)
            } else {
                copy.add(activityId)
            }
            setActivitiesToDelete(copy)
        },
        [activitiesToDelete, setActivitiesToDelete]
    )

    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: 2
            }}
        >
            <CoreTypography
                variant="overline"
                color={theme.palette.text.primary}
            >
                {formatMessage(MANUAL_ACTIVITY_CARD_TITLE)}
            </CoreTypography>
            <CoreTypography variant="h5" color={theme.palette.text.primary}>
                {formatMessage(POINTS_MESSAGE, {
                    points: manualActivityTotal
                })}
            </CoreTypography>
            <Stack gap={2} width="100%">
                {manualActivities.map((activity) => (
                    <ManualActivityRow
                        key={activity.id}
                        activity={activity}
                        toDelete={activitiesToDelete.has(activity.id)}
                        toggleDelete={handleToggleDelete}
                    />
                ))}
            </Stack>
        </Card>
    )
}

export default ManualActivitiesCard
