import { useEffect, useMemo } from 'react'
import { debounce } from 'lodash'

const isWindowDefined = typeof window !== 'undefined'
const isDocumentDefined = typeof document !== 'undefined'

/**
 * Registers a callback to be called when the window or tab gains focus or visibility changes.
 *
 * @param callback - The function to be called when the window or tab gains focus or visibility changes.
 * @param delay - The number of milliseconds to debounce the callback. Defaults to 150ms.
 */
const useOnViewFocus = (callback: () => unknown, delay = 150) => {
    // Debounce the callback to prevent it from being called multiple times in quick succession
    // in the case that the document and window events are fired at the same time.
    const debouncedCallback = useMemo(
        () => callback && debounce(callback, delay),
        [callback, delay]
    )

    useEffect(() => {
        // `add/removeEventListener` do not exist on the window object in React Native
        if (isWindowDefined && window.addEventListener) {
            window.addEventListener('focus', debouncedCallback)
        }
        if (isDocumentDefined) {
            document.addEventListener('visibilitychange', debouncedCallback)
        }

        return () => {
            if (isWindowDefined && window.removeEventListener) {
                window.removeEventListener('focus', debouncedCallback)
            }
            if (isDocumentDefined) {
                document.removeEventListener(
                    'visibilitychange',
                    debouncedCallback
                )
            }
        }
    }, [debouncedCallback])
}

export default useOnViewFocus
