import { Box, Button, DialogActions, Grid, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafDialog,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { defineMessage, useIntl } from 'react-intl'
import AppleHealthCard from '../AppleHealthCard/AppleHealthCard'
import MoreWellBeingAppsCard from '../MoreWellBeingAppsCard/MoreWellBeingAppsCard'
import { useCallback, useMemo, useRef, useState } from 'react'
import { AppleHealthHowToDisconnectDialogContent } from '../AppleHealthHowToDisconnectDialog'
import { AppleHealthHowToConnectDialogContent } from '../AppleHealthHowToConnectDialog'
import {
    HOW_TO_CONNECT_DIALOG_TITLE,
    HOW_TO_DISCONNECT_DIALOG_TITLE
} from '../../utils/appleHealthDialogConstants'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { OuraCard } from '../OuraCard'
import { StaticDialogContent } from '../StaticDialogContent'

const MAIN_DIALOG_TITLE = defineMessage({
    defaultMessage: 'Health & Fitness apps',
    description: 'The heading for the Health & Fitness apps page'
})

const MAIN_PAGE_DESCRIPTION = defineMessage({
    defaultMessage:
        'Connect health and fitness apps from the options below for a more whole-human Thrive experience.',
    description: 'The description for the Well-being apps page'
})

const PRIVACY_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Privacy info',
    description: 'Label for button that shows the user privacy info'
})

const CANCEL_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Cancel',
    description: 'Label for button that cancels showing the current dialog'
})

type DialogTitleProps = {
    title: string
    showBackButton?: boolean
    onBackButtonClick?: () => void
}

const DialogTitle = ({
    title,
    showBackButton = false,
    onBackButtonClick
}: DialogTitleProps) => {
    return (
        <Box display="flex" alignItems="center" gap={1}>
            {!!showBackButton && (
                <IconButton aria-label="back" onClick={onBackButtonClick}>
                    <LeafIcon icon={'chevron-left'} />
                </IconButton>
            )}
            {title}
        </Box>
    )
}

type MainContentProps = {
    onClose: () => void
    onShowHowToConnect: () => void
    onShowHowToDisconnect: () => void
    onShowPrivacyModal: () => void
}

const MainContent = ({
    onClose,
    onShowHowToConnect,
    onShowHowToDisconnect,
    onShowPrivacyModal
}: MainContentProps) => {
    const intl = useIntl()
    const showOuraCard = useIsFeatureEnabled(
        'ouraOAuthLoopEnabled',
        false,
        true
    )

    return (
        <Grid container rowSpacing={3}>
            <Grid item xs={12}>
                <CoreTypography variant="body1">
                    {intl.formatMessage(MAIN_PAGE_DESCRIPTION)}
                </CoreTypography>
            </Grid>
            <Grid item xs={12}>
                <AppleHealthCard
                    onCloseParentModal={onClose}
                    onShowHowToConnect={onShowHowToConnect}
                    onShowHowToDisconnect={onShowHowToDisconnect}
                    onShowPrivacyModal={onShowPrivacyModal}
                />
            </Grid>
            {showOuraCard && (
                <Grid item xs={12}>
                    <OuraCard />
                </Grid>
            )}
            <Grid item xs={12}>
                <MoreWellBeingAppsCard />
            </Grid>
        </Grid>
    )
}

type WellBeingAppsDialogProps = {
    onClose: () => void
    onShowPrivacyModal?: () => void
}

const WellBeingAppsDialog: React.FC<WellBeingAppsDialogProps> = ({
    onClose,
    onShowPrivacyModal
}) => {
    const theme = useTheme()
    const intl = useIntl()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [showHowToConnect, setShowHowToConnect] = useState(false)
    const [showHowToDisconnect, setShowHowToDisconnect] = useState(false)

    const handleShowPrivacyModal = useCallback(() => {
        onShowPrivacyModal?.()
        // Hack: Reduces flashing when opening the privacy modal and closing this one
        setTimeout(onClose, 150)
    }, [onClose, onShowPrivacyModal])

    const handleCancel = useCallback(() => {
        showHowToConnect && setShowHowToConnect(false)
        showHowToDisconnect && setShowHowToDisconnect(false)
    }, [showHowToConnect, showHowToDisconnect])

    const handleShowHowToDisconnect = useCallback(
        () => setShowHowToDisconnect(true),
        []
    )
    const handleShowHowToConnect = useCallback(
        () => setShowHowToConnect(true),
        []
    )

    const content = useMemo(() => {
        if (showHowToConnect) {
            return <AppleHealthHowToConnectDialogContent />
        }
        if (showHowToDisconnect) {
            return <AppleHealthHowToDisconnectDialogContent />
        }
        return (
            <MainContent
                onClose={onClose}
                onShowPrivacyModal={handleShowPrivacyModal}
                onShowHowToConnect={handleShowHowToConnect}
                onShowHowToDisconnect={handleShowHowToDisconnect}
            />
        )
    }, [
        handleShowHowToConnect,
        handleShowHowToDisconnect,
        handleShowPrivacyModal,
        onClose,
        showHowToConnect,
        showHowToDisconnect
    ])

    const isNotMainContent = useMemo(() => {
        return showHowToConnect || showHowToDisconnect
    }, [showHowToConnect, showHowToDisconnect])

    const dialogTitle = useMemo(() => {
        if (showHowToConnect) {
            return HOW_TO_CONNECT_DIALOG_TITLE
        }
        if (showHowToDisconnect) {
            return HOW_TO_DISCONNECT_DIALOG_TITLE
        }
        return MAIN_DIALOG_TITLE
    }, [showHowToConnect, showHowToDisconnect])

    return (
        <LeafDialog
            open={true}
            onClose={onClose}
            dialogTitle={
                <DialogTitle
                    title={intl.formatMessage(dialogTitle)}
                    showBackButton={isNotMainContent}
                    onBackButtonClick={handleCancel}
                />
            }
            fullScreen={isSmallScreen}
        >
            <StaticDialogContent data-testid="well-being-management-dialog-content">
                {content}
            </StaticDialogContent>
            <DialogActions>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Box>
                        <Button variant="text" onClick={handleShowPrivacyModal}>
                            <CoreTypography customVariant="buttonNormal">
                                {intl.formatMessage(PRIVACY_BUTTON_TEXT)}
                            </CoreTypography>
                        </Button>
                    </Box>
                    {isNotMainContent && !isSmallScreen && (
                        <Box>
                            <Button variant="contained" onClick={handleCancel}>
                                <CoreTypography customVariant="buttonNormal">
                                    {intl.formatMessage(CANCEL_BUTTON_TEXT)}
                                </CoreTypography>
                            </Button>
                        </Box>
                    )}
                </Box>
            </DialogActions>
        </LeafDialog>
    )
}

export default WellBeingAppsDialog
