import {
    DialogContent,
    Button,
    Box,
    useMediaQuery,
    DialogActions
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { useIntl } from 'react-intl'
import {
    PRIVACY_BUTTON_TEXT,
    CLOSE_BUTTON_TEXT,
    HOW_TO_CONNECT_DIALOG_TITLE
} from '../../utils/appleHealthDialogConstants'
import AppleHealthHowToConnectDialogContent from './AppleHealthHowToConnectDialogContent'

type AppleHealthHowToConnectDialogProps = {
    open: boolean
    onClose: () => void
    onShowPrivacyInfo: () => void
}

const AppleHealthHowToConnectDialog: React.FC<
    AppleHealthHowToConnectDialogProps
> = ({ open, onClose, onShowPrivacyInfo }) => {
    const intl = useIntl()
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <LeafDialog
            data-testid="wearables-how-to-connect-dialog"
            open={open}
            dialogTitle={intl.formatMessage(HOW_TO_CONNECT_DIALOG_TITLE)}
            onClose={onClose}
            fullScreen={isMobileScreen}
        >
            <DialogContent>
                <Box sx={{ overflowX: 'clip' }}>
                    <AppleHealthHowToConnectDialogContent />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Button variant="text" onClick={onShowPrivacyInfo}>
                        <CoreTypography customVariant="buttonNormal">
                            {intl.formatMessage(PRIVACY_BUTTON_TEXT)}
                        </CoreTypography>
                    </Button>
                    <Button variant="contained" onClick={onClose}>
                        <CoreTypography customVariant="buttonNormal">
                            {intl.formatMessage(CLOSE_BUTTON_TEXT)}
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogActions>
        </LeafDialog>
    )
}

export default AppleHealthHowToConnectDialog
