import { UserSourceDto } from '../../graphql/generated/autogenerated'
import { FC } from 'react'
import { Box, Divider } from '@mui/material'
import ConnectedSource from './ConnectedSource'
import { SourceName } from 'src/enums'

type ConnectedSourceListProps = {
    userSources: UserSourceDto[]
}

const ConnectedSourcesList: FC<ConnectedSourceListProps> = ({
    userSources
}) => {
    const connectedSources = userSources
        .filter((s) => s.connected)
        .sort((a, b) => (a.sourceName > b.sourceName && 1) || -1)

    return (
        <Box>
            {connectedSources.map((s, i) => (
                <Box key={s.sourceId}>
                    <ConnectedSource
                        sourceName={s.sourceName as SourceName}
                        lastSynced={s.lastSynced}
                    />
                    {i + 1 < connectedSources.length && (
                        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                    )}
                </Box>
            ))}
        </Box>
    )
}

export default ConnectedSourcesList
