import { Box, Button } from '@mui/material'
import React, {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import {
    HumanApiButtonProps,
    HumanApiProviderState,
    HumanAPIClient
} from '../../utils/humanAPI'
import { useGetProfileQuery } from '../../graphql/generated/autogenerated'
import { useAppSelector, captureException } from '@thriveglobal/thrive-web-core'
import { getIsInIframe } from '../../utils'

// IMPORTANT - required for HAPI callbacks to fire
const HAPI_VERSION = 'V2'
const HumanApiContext = createContext<HumanApiProviderState>({
    isReturningUser: false,
    setIsReturningUser: () => null,
    connectToken: undefined,
    error: undefined,
    connectReady: false,
    wasNewDeviceConnected: false,
    setWasNewDeviceConnected: () => null,
    updateActivitySummary: () => Promise.resolve({})
})

const messages = defineMessages({
    connect: {
        defaultMessage: 'Connect',
        description: ''
    }
})

const HumanApiProvider = ({ children }: PropsWithChildren<{}>) => {
    const [connectReady, setConnectReady] = useState(false)
    const [wasNewDeviceConnected, setWasNewDeviceConnected] = useState(false)
    const [isReturningUser, setIsReturningUser] = useState<boolean>(false)
    const [connectToken, setConnectToken] = useState<string>()
    const [error, setError] = useState<unknown>()

    const user = useAppSelector((appState) => appState.user)

    const { data, error: fetchProfileError } = useGetProfileQuery({
        skip: !!user.email
    })

    if (fetchProfileError) {
        captureException(fetchProfileError, {
            message: `Failed to fetch user profile`
        })
    }

    useEffect(() => {
        // don't fetch HAPI token if previous request is completed or failed
        if (!!error || connectReady) {
            return () => {
                // noop
            }
        }

        // track if effect is canceled before request is completed
        let isCanceled = false

        const email = user.email || data?.identity?.me?.email

        // Returns an idToken (returning users) or sessionToken (new users)
        email &&
            user.userId &&
            HumanAPIClient.getHapiToken({
                external_id: email,
                thrive_user_id: user.userId
            })
                .then((tokenResult) => {
                    if (!isCanceled) {
                        try {
                            const result =
                                tokenResult.data.wearables.getHapiToken
                            const { __typename } = result
                            let tokenFromResponse: string | null = null

                            if (__typename === 'HapiIdToken') {
                                tokenFromResponse = result.idToken
                            } else if (__typename === 'HapiSessionToken') {
                                tokenFromResponse = result.sessionToken
                            }
                            setConnectReady(true)
                            setIsReturningUser(__typename === 'HapiIdToken')
                            setConnectToken(tokenFromResponse ?? connectToken)
                        } catch (getHapiTokenError) {
                            setError(getHapiTokenError)
                            if (getHapiTokenError instanceof Error) {
                                captureException(getHapiTokenError, {
                                    message: `Failed to set HAPI Token for connect integration`
                                })
                            }
                        }
                    }
                })
                .catch((getHapiTokenError) => {
                    if (!isCanceled) {
                        setError(getHapiTokenError)
                        if (getHapiTokenError instanceof Error) {
                            captureException(getHapiTokenError, {
                                message: `Failed to fetch HAPI Token`
                            })
                        }
                    }
                })
        return () => {
            isCanceled = true
        }
    }, [
        connectReady,
        error,
        user.email,
        user.userId,
        data?.identity?.me?.email,
        connectToken
    ])

    const state = useMemo<HumanApiProviderState>(
        () => ({
            isReturningUser,
            setIsReturningUser,
            connectToken,
            error,
            connectReady,
            wasNewDeviceConnected,
            setWasNewDeviceConnected,
            updateActivitySummary: HumanAPIClient.updateActivitySummary
        }),
        [
            isReturningUser,
            connectToken,
            error,
            connectReady,
            wasNewDeviceConnected
        ]
    )

    return (
        <HumanApiContext.Provider value={state}>
            {children}
        </HumanApiContext.Provider>
    )
}

const useHumanApiContext = (): HumanApiProviderState => {
    const context = useContext(HumanApiContext)
    if (context === undefined) {
        throw new Error(
            'useHumanApiContext must be used within a HumanApiProvider'
        )
    }
    return context
}

const ConnectButton: React.FC<HumanApiButtonProps> = ({
    cta,
    callbackOpts = {}
}) => {
    const intl = useIntl()
    const {
        connectReady,
        connectToken,
        updateActivitySummary,
        setWasNewDeviceConnected,
        setIsReturningUser
    } = useContext(HumanApiContext)

    const hapiConnect = HumanAPIClient.setupConnect({
        ...callbackOpts,
        closeCallback: updateActivitySummary,
        connectCallback: () => {
            setWasNewDeviceConnected(true)
            setIsReturningUser(true)
        }
    })

    return (
        <Button
            data-hapi-token={connectToken}
            disabled={!connectReady}
            onClick={() => {
                hapiConnect.open({
                    version: HAPI_VERSION,
                    token: connectToken
                })
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CoreTypography customVariant="buttonNormal">
                    {cta ?? intl.formatMessage(messages.connect)}
                </CoreTypography>
                <LeafIcon icon={'arrow-right'} sx={{ marginLeft: '8px' }} />
            </Box>
        </Button>
    )
}

const HostedAppConnectButton: React.FC<HumanApiButtonProps> = ({
    cta,
    isAcknowledged,
    openPrivacyModal,
    ...buttonProps
}) => {
    const intl = useIntl()
    const {
        connectReady,
        connectToken,
        isReturningUser,
        wasNewDeviceConnected
    } = useContext(HumanApiContext)

    const isPrivacyAcknowledged =
        isAcknowledged || isReturningUser || wasNewDeviceConnected

    const isInIframe = useMemo(() => getIsInIframe(), [])

    const navigateToHostedApp = useCallback(() => {
        const hapiLink = `https://hapi-link.humanapi.co/connect?clientId=${
            process.env.HUMAN_API_CLIENT_ID
        }&token=${connectToken}&callback=${encodeURIComponent(
            window.location.href
        )}`

        if (connectReady && isPrivacyAcknowledged) {
            if (isInIframe) {
                window.open(hapiLink, '_blank')
            } else {
                window.location.replace(hapiLink)
            }
        } else {
            openPrivacyModal?.()
        }
    }, [
        connectToken,
        connectReady,
        isPrivacyAcknowledged,
        openPrivacyModal,
        isInIframe
    ])

    useEffect(() => {
        isAcknowledged && !isReturningUser && navigateToHostedApp()
    }, [isAcknowledged, isReturningUser, navigateToHostedApp])

    return (
        <Button
            data-hapi-token={connectToken}
            disabled={!connectReady}
            onClick={navigateToHostedApp}
            variant="contained"
            color="secondary"
            sx={{ display: 'flex', alignItems: 'center' }}
            {...buttonProps}
        >
            <CoreTypography customVariant="buttonNormal">
                {cta ?? intl.formatMessage(messages.connect)}
            </CoreTypography>
        </Button>
    )
}

const HumanApiConnectButton: React.FC<HumanApiButtonProps> = (props) => {
    return (
        <Box className="hapi__token-container">
            <ConnectButton {...props} />
        </Box>
    )
}

export {
    HumanApiProvider,
    useHumanApiContext,
    HumanApiConnectButton,
    HostedAppConnectButton
}
