import { gql } from '@apollo/client'

// fetch either a session or id token used to open the Human API Connect modal
// session token is for users who have not connected yet, id token is for users who have
export const GET_HAPI_TOKEN = gql`
    query getHapiToken($email: String!, $thriveUserId: String!) {
        wearables {
            getHapiToken(email: $email, thriveUserId: $thriveUserId) {
                __typename
                ... on HapiIdToken {
                    idToken
                    tokenType
                    idRefreshToken
                    idTokenExpiresIn
                }
                ... on HapiSessionToken {
                    sessionToken
                    humanId
                    expiresIn
                }
            }
        }
    }
`

export const GET_USER_SOURCES = gql`
    query GetUserSources {
        wearables {
            users {
                sources {
                    directSources {
                        sourceId
                        sourceName
                        connected
                        created
                        lastSynced
                    }
                    humanApiSources {
                        sourceId
                        sourceName
                        connected
                        created
                        lastSynced
                        externalSourceId
                    }
                }
            }
        }
    }
`

export const GET_WEARABLES_ACCESS = gql`
    query GetWearablesAccess {
        wearables {
            wearablesAccess {
                isActivityDataEnabled
                isSleepDataEnabled
            }
        }
    }
`

export const GET_HAPI_SOURCE_REFERENCE = gql`
    query GetHapiSourceReference {
        wearables {
            humanApi {
                sourceReference {
                    sourceId
                    sourceName
                    humanApiSourceId
                }
            }
        }
    }
`

export const GET_ACTIVITY_FOR_DATE = gql`
    query GetActivityForDate($date: ISODate!) {
        wearables {
            activity {
                getActivityEntriesForDate(date: $date) {
                    date
                    durationMins
                    id
                    label
                    steps
                }
                getStepCount(date: $date)
            }
        }
    }
`

export const GET_USER = gql`
    query GetUser {
        wearables {
            users {
                getUser {
                    companyId
                    created
                    hasActiveAppleHealthDirectConnection
                    hasActiveHumanApiConnection
                    hasAppleHealthDirectConnection
                    hasHumanApiConnection
                    isHumanApiUser
                    userId
                    hasOuraDirectConnection
                    hasActiveOuraDirectConnection
                }
            }
        }
    }
`

export const GET_OURA_OAUTH_START_URL = gql`
    query GetOuraOAuthStartUrl($redirectPath: String) {
        wearables {
            oura {
                oauthAuthorisationUrl(redirectPath: $redirectPath)
            }
        }
    }
`

export const GET_STEP_COUNT_FOR_DATE = gql`
    query GetStepCount($date: ISODate!) {
        wearables {
            activity {
                getStepCount(date: $date)
            }
        }
    }
`

export const GET_MANUAL_ACTIVITY_OPTIONS = gql`
    query GetManualActivityOptions {
        wearables {
            activity {
                getManualActivityOptions {
                    uuid
                    activityDescription
                    stepsPerMin
                    isCoreStep
                }
            }
        }
    }
`
