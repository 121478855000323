import { defineMessages } from 'react-intl'
import { SourceName } from '../enums/sourceNames'

export const hapiSyncCadence = defineMessages({
    [SourceName.Fitbit]: {
        defaultMessage: 'Data updates every hour',
        description: 'Text that indicates the data sync cadence for the source'
    },
    [SourceName.Garmin]: {
        defaultMessage: 'Data updates every hour',
        description: 'Text that indicates the data sync cadence for the source'
    },
    [SourceName.iHealth]: {
        defaultMessage: 'Data updates every hour',
        description: 'Text that indicates the data sync cadence for the source'
    },
    [SourceName.MapMyFitness]: {
        defaultMessage: 'Data updates every 2 hours',
        description: 'Text that indicates the data sync cadence for the source'
    },
    [SourceName.MyFitnessPal]: {
        defaultMessage: 'Data updates every 30 minutes',
        description: 'Text that indicates the data sync cadence for the source'
    },
    [SourceName.Strava]: {
        defaultMessage: 'Data updates every hour',
        description: 'Text that indicates the data sync cadence for the source'
    },
    [SourceName.Vitadock]: {
        defaultMessage: 'Data updates every 30 minutes',
        description: 'Text that indicates the data sync cadence for the source'
    },
    [SourceName.Withings]: {
        defaultMessage: 'Data updates every 4 hours',
        description: 'Text that indicates the data sync cadence for the source'
    }
})
