import { Box, Collapse } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import StepsProgress from '../StepsProgress/StepsProgress'
import { WearablesNativeConnectButton } from '../WearablesNativeConnectButton'
import { useGetWearablesUser } from '../../hooks'

const messages = defineMessages({
    trackActivityHeader: {
        defaultMessage: 'Track your activity automatically',
        description:
            'description text on connecting a device it will automatically track your step progress for the challenge'
    },
    yourActivityHeader: {
        defaultMessage: 'Your activity',
        description:
            'title text for the box that show the current step progress a user has in step challenge'
    },
    connectADevice: {
        defaultMessage: 'Connect an app',
        description:
            'button text that will open up a new tab to allow a user to connect a health and fitness app'
    },
    manageYourDevice: {
        defaultMessage: 'Manage connected apps',
        description:
            'button text that will open a new tab to allow users to manage the apps they currently have connected'
    }
})

export interface WearablesConnectOrProgressProps {
    refetch: () => void
}

const WearablesConnectOrProgress: React.FC<WearablesConnectOrProgressProps> = ({
    refetch
}) => {
    const theme = useTheme()
    const intl = useIntl()

    const { isLoading, hasAnyActiveConnection } = useGetWearablesUser()

    return (
        <>
            <Collapse in mountOnEnter unmountOnExit>
                <Box
                    borderRadius={4}
                    sx={{
                        padding: theme.spacing(3),
                        my: 3,
                        bgcolor: theme.palette.background.default
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <LeafIcon
                            icon={'watch'}
                            color="accent"
                            sx={{
                                marginTop: theme.spacing(0.5)
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                marginLeft: theme.spacing(1)
                            }}
                        >
                            <Box>
                                <CoreTypography
                                    variant="h3"
                                    sx={{
                                        color: theme.palette.text.primary,
                                        marginBottom: theme.spacing(1)
                                    }}
                                >
                                    {!isLoading && !hasAnyActiveConnection
                                        ? intl.formatMessage(
                                              messages.trackActivityHeader
                                          )
                                        : intl.formatMessage(
                                              messages.yourActivityHeader
                                          )}
                                </CoreTypography>
                                {!isLoading && !hasAnyActiveConnection && (
                                    <>
                                        <CoreTypography
                                            sx={{
                                                color: theme.palette.text
                                                    .primary,
                                                marginBottom: theme.spacing(2)
                                            }}
                                        >
                                            <FormattedMessage
                                                defaultMessage="Connect your health activity to Thrive"
                                                description="description text indicating to the user that they should connect a device to track their activity as part of this challenge"
                                            />
                                        </CoreTypography>
                                        <WearablesNativeConnectButton
                                            buttonText={intl.formatMessage(
                                                messages.connectADevice
                                            )}
                                        />
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Collapse
                        in={!isLoading && hasAnyActiveConnection}
                        mountOnEnter
                        unmountOnExit
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <StepsProgress refetch={refetch} />
                            <WearablesNativeConnectButton
                                buttonText={intl.formatMessage(
                                    messages.manageYourDevice
                                )}
                            />
                        </Box>
                    </Collapse>
                </Box>
            </Collapse>
        </>
    )
}

export default WearablesConnectOrProgress
