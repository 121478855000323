import { ApolloError } from '@apollo/client'
import { useGetWearablesAccessQuery } from '../../graphql/generated/autogenerated'

type UseWearablesAccessReturn =
    | {
          loading: true
          error: undefined
          isActivityDataEnabled: undefined
          isSleepDataEnabled: undefined
      }
    | {
          loading: false
          error: ApolloError
          isActivityDataEnabled: undefined
          isSleepDataEnabled: undefined
      }
    | {
          loading: false
          error: undefined
          isActivityDataEnabled: boolean
          isSleepDataEnabled: boolean
      }

export const useWearablesAccess = (): UseWearablesAccessReturn => {
    const { data, loading, error } = useGetWearablesAccessQuery()

    if (loading) {
        return {
            loading: true,
            error: undefined,
            isActivityDataEnabled: undefined,
            isSleepDataEnabled: undefined
        }
    }

    if (error) {
        return {
            loading: false,
            error,
            isActivityDataEnabled: undefined,
            isSleepDataEnabled: undefined
        }
    }

    return {
        loading: false,
        error: undefined,
        isActivityDataEnabled:
            data?.wearables.wearablesAccess.isActivityDataEnabled || false,
        isSleepDataEnabled:
            data?.wearables.wearablesAccess.isSleepDataEnabled || false
    }
}
