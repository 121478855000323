import {
    Button,
    DialogActions,
    DialogContent,
    Stack,
    useMediaQuery
} from '@mui/material'
import { format } from '@thriveglobal/thrive-web-core'
import {
    CoreAlert,
    CoreTypography,
    LeafDialog,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import TotalActivityCountCard from './TotalActivityCountCard'
import AppsActivityCountCard from './AppsActivityCountCard'
import ManualActivityCard from './ManualActivitiesCard'
import ManualActivityDatePicker from './ManualActivityDatePicker'
import {
    useDeleteManualActivityEntriesMutation,
    useGetActivityForDateQuery
} from '../../hooks'
import { Avo } from '@thriveglobal/thrive-web-tracking'

const DIALOG_TITLE_YOUR_ACTIVITY = defineMessage({
    defaultMessage: 'Your activity',
    description: 'title for the "Your activity" modal'
})

const ADD_ACTIVITY_DIALOG_DESCRIPTION = defineMessage({
    defaultMessage: 'Select a day to view or edit your activity for that day.',
    description: 'description for the "Your activity" modal'
})

const SAVE_ERROR = defineMessage({
    defaultMessage:
        'Could not save changes. Try again and if the issue persists, please report this as a bug.',
    description: 'error message for the manual activity modal'
})

const CANCEL_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Cancel',
    description: 'cancel manual activity button'
})

const SAVE_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Save changes',
    description: 'save manual activity button'
})

export interface ViewManualActivityModalProps {
    open: boolean
    startDate: Date
    onClose: () => void
    onEditActivity: (steps: number, isToday?: boolean) => void
}

const ViewManualActivityModal: React.FC<ViewManualActivityModalProps> = ({
    open,
    startDate,
    onClose,
    onEditActivity
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [activityDate, setActivityDate] = useState<string>()
    const [mutationError, setMutationError] = useState(false)
    const [activitiesToDelete, setActivitiesToDelete] = useState<Set<string>>(
        new Set()
    )

    const viewTracked = useRef(false)

    const [deleteManualActivities, { loading: deleteMutationLoading }] =
        useDeleteManualActivityEntriesMutation()

    const {
        data: activityData,
        loading: activityDataLoading,
        refetch
    } = useGetActivityForDateQuery({
        variables: {
            date: activityDate
        },
        skip: !activityDate
    })

    const manualActivities = useMemo(
        () => activityData?.wearables.activity.getActivityEntriesForDate || [],
        [activityData]
    )

    const manualActivitySteps = useMemo(
        () =>
            manualActivities
                .filter((activity) => !activitiesToDelete.has(activity.id))
                .reduce((a, b) => a + b.steps, 0),
        [manualActivities, activitiesToDelete]
    )

    const totalActivitySteps = useMemo(() => {
        const initialTotalSteps =
            activityData?.wearables.activity.getStepCount || 0
        const deletedSteps = manualActivities
            .filter((activity) => activitiesToDelete.has(activity.id))
            .reduce((a, b) => a + b.steps, 0)
        // Should never be negative, but just in case
        return Math.max(0, initialTotalSteps - deletedSteps)
    }, [
        activitiesToDelete,
        activityData?.wearables.activity.getStepCount,
        manualActivities
    ])

    const appsActivitySteps = useMemo(
        // Should never be negative, but just in case
        () => Math.max(0, totalActivitySteps - manualActivitySteps),
        [manualActivitySteps, totalActivitySteps]
    )

    const isSaveDisabled = useMemo(
        () =>
            activityDataLoading ||
            deleteMutationLoading ||
            activitiesToDelete.size < 1,
        [activityDataLoading, deleteMutationLoading, activitiesToDelete.size]
    )

    const isToday = useMemo(
        () => activityDate === format(new Date()),
        [activityDate]
    )

    const handleClearError = useCallback(() => {
        setMutationError(false)
    }, [])

    const handleOnClose = useCallback(() => {
        onClose()
        setActivityDate(undefined)
        setActivitiesToDelete(new Set())
        handleClearError()
    }, [handleClearError, onClose])

    const handleSetActivityDate: React.ChangeEventHandler<HTMLInputElement> =
        useCallback(
            (event) => {
                setActivityDate(event.target.value)
                setActivitiesToDelete(new Set())
                handleClearError()
            },
            [handleClearError]
        )

    const handleOnEditActivity = useCallback(
        () => onEditActivity(totalActivitySteps, isToday),
        [onEditActivity, totalActivitySteps, isToday]
    )

    const handleOnSave = useCallback(async () => {
        if (isSaveDisabled) return
        handleClearError()
        // Get the total duration and steps of the activities to be deleted
        const { deletedDuration, deletedSteps } = manualActivities
            .filter((activity) => activitiesToDelete.has(activity.id))
            .reduce(
                (acc, curr) => ({
                    deletedDuration: acc.deletedDuration + curr.durationMins,
                    deletedSteps: acc.deletedSteps + curr.steps
                }),
                { deletedDuration: 0, deletedSteps: 0 }
            )

        try {
            await deleteManualActivities({
                variables: {
                    date: activityDate,
                    entryIds: Array.from(activitiesToDelete)
                }
            })

            if (activityDate) {
                Avo.activityRemoved({
                    activityDate: activityDate,
                    durationInMinutes: deletedDuration,
                    numberOfActivities: 1,
                    stepCount: deletedSteps
                })
            }

            handleOnEditActivity()
            handleOnClose()
        } catch (e) {
            setMutationError(true)
        }
    }, [
        activitiesToDelete,
        activityDate,
        deleteManualActivities,
        handleClearError,
        handleOnClose,
        handleOnEditActivity,
        isSaveDisabled,
        manualActivities
    ])

    if (open) {
        // The component is not unmounted when the dialog is closed, so we need to
        // refetch today's data when the dialog is re-opened.
        if (!activityDate) {
            const today = format(new Date())
            setActivityDate(today)
            refetch({ date: today })
        }
    }

    // Only track the view event once per open/close cycle
    if (open && !viewTracked.current) {
        viewTracked.current = true

        Avo.promptSelected({
            activityType: 'challenge_activity_viewed',
            contentFormatType: null,
            contentId: null,
            contentSubtype: null,
            contentTitle: null,
            contentType: null,
            contentUrl: null,
            displayText: ADD_ACTIVITY_DIALOG_DESCRIPTION.defaultMessage,
            featureType: 'challenge',
            isOnboarding: false,
            resetId: null,
            resetName: null,
            checkInDate: null,
            contentSource: null,
            isAutomaticCheckin: null,
            microstepBody: null,
            microstepId: null,
            microstepName: null,
            notificationCount: null,
            tabName: null,
            tstPlatform: null,
            userId_: '',
            nudgeType: null
        })
    } else if (!open) {
        viewTracked.current = false
    }

    return (
        <LeafDialog
            dialogTitle={formatMessage(DIALOG_TITLE_YOUR_ACTIVITY)}
            open={open}
            fullWidth
            maxWidth="sm"
            fullScreen={isMobile}
            onClose={handleOnClose}
        >
            <DialogContent>
                <CoreTypography variant="body1" color="text.secondary">
                    {formatMessage(ADD_ACTIVITY_DIALOG_DESCRIPTION)}
                </CoreTypography>
                <Stack alignItems="center" paddingTop={2}>
                    <Stack gap={2} width="100%">
                        <ManualActivityDatePicker
                            activityDate={activityDate}
                            onChange={handleSetActivityDate}
                            disabled={activitiesToDelete.size > 0}
                            startDate={startDate}
                        />
                        <AppsActivityCountCard
                            appsActivityTotal={appsActivitySteps}
                        />
                        <ManualActivityCard
                            manualActivities={manualActivities}
                            manualActivityTotal={manualActivitySteps}
                            activitiesToDelete={activitiesToDelete}
                            setActivitiesToDelete={setActivitiesToDelete}
                        />
                        <TotalActivityCountCard
                            activityTotal={totalActivitySteps}
                        />
                        {mutationError && (
                            <CoreAlert
                                severity="error"
                                aria-live="polite"
                                onClose={handleClearError}
                            >
                                <CoreTypography
                                    variant="body1"
                                    color={theme.palette.error.main}
                                >
                                    {formatMessage(SAVE_ERROR)}
                                </CoreTypography>
                            </CoreAlert>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    width="100%"
                    gap={1}
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                >
                    <Button
                        color="secondary"
                        onClick={handleOnClose}
                        variant="contained"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(CANCEL_BUTTON_TEXT)}
                        </CoreTypography>
                    </Button>
                    <LoadingButton
                        loading={deleteMutationLoading}
                        fixWidth={true}
                        disabled={isSaveDisabled}
                        color="primary"
                        onClick={handleOnSave}
                        variant="contained"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(SAVE_BUTTON_TEXT)}
                        </CoreTypography>
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </LeafDialog>
    )
}
export default memo(ViewManualActivityModal)
