import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppleHealthAccessStatus } from '../useAppleHealthAccessStatus/useAppleHealthAccessStatus'
import { pullAppleHealthSleepData, pullAppleHealthStepsData } from '../../utils'
import { DD, useAppSelector } from '@thriveglobal/thrive-web-core'
import { AppleHealthStepsInput } from '../../graphql/generated/autogenerated'
import {
    useSaveAppleHealthStepsDataMutation,
    useProcessRawAppleHealthSleepDataMutation
} from '..'
import { AppleHealthSleepArray, AppleHealthStepsArray } from '../../schemas'
import endOfDay from 'date-fns/endOfDay'
import getDayOfYear from 'date-fns/getDayOfYear'
import getYear from 'date-fns/getYear'
import parseISO from 'date-fns/parseISO'
import startOfDay from 'date-fns/startOfDay'
import subMonths from 'date-fns/subMonths'

type DateIso = string

const appleHealthDateToBackendSupportedDate = (appleIso: string) => {
    if (appleIso.includes('+') || appleIso.includes('-')) {
        return appleIso.slice(0, -2) + ':' + appleIso.slice(-2)
    }
    return appleIso
}

const captureUnknownError = (error: unknown, userId?: string | null) => {
    DD.captureError(
        `An unknown error occurred while saving apple health data: [userId: ${userId}]`
    )
}

const captureStepsError = (error: unknown, userId?: string | null) => {
    if (error instanceof Error) {
        DD.captureError(
            `Error saving steps data: [userId: ${userId}, error: ${error?.message}]`,
            error
        )
    } else {
        captureUnknownError(error, userId)
    }
}

const captureSleepError = (error: unknown, userId?: string | null) => {
    if (error instanceof Error) {
        DD.captureError(
            `Error saving sleep data: [userId: ${userId}, error: ${error?.message}]`,
            error
        )
    } else {
        captureUnknownError(error, userId)
    }
}

export const useSyncAppleHealthData = () => {
    const {
        loadingAccess,
        hasGrantedSleepAccess,
        hasGrantedStepsAccess,
        hasDeniedAccess
    } = useAppleHealthAccessStatus()

    const { appleHealthSleepData, appleHealthStepData } = useAppSelector(
        (state) => state.hybrid
    )

    const { userId } = useAppSelector((state) => state.user)

    const [hasRequestedSleepData, setHasRequestedSleepData] = useState(false)
    const [hasRequestedStepsData, setHasRequestedStepsData] = useState(false)
    const [hasSyncedStepsData, setHasSyncedStepsData] = useState(false)
    const [hasProcessedRawSleepData, setHasProcessedRawSleepData] =
        useState(false)

    // Get the UTC ISO of the end of today
    const todayIso = useMemo(() => {
        return endOfDay(new Date()).toISOString()
    }, [])

    // Get the UTC ISO of the start of day 1 month ago
    const oneMonthAgoIso = useMemo(() => {
        return startOfDay(subMonths(new Date(), 1)).toISOString()
    }, [])

    const shouldSyncSleepData = useMemo(
        () => !loadingAccess && !hasDeniedAccess && hasGrantedSleepAccess,
        [hasDeniedAccess, hasGrantedSleepAccess, loadingAccess]
    )

    const shouldSyncStepsData = useMemo(
        () => !loadingAccess && !hasDeniedAccess && hasGrantedStepsAccess,
        [hasDeniedAccess, hasGrantedStepsAccess, loadingAccess]
    )

    const [saveAppleHealthStepsData] = useSaveAppleHealthStepsDataMutation({
        onError: (error) => captureStepsError(error, userId)
    })

    const [processRawAppleHealthSleepData] =
        useProcessRawAppleHealthSleepDataMutation({
            onError: (error) => captureSleepError(error, userId)
        })

    const sleepDataLoading = useMemo(
        () => appleHealthSleepData == null,
        [appleHealthSleepData]
    )
    const stepsDataLoading = useMemo(
        () => appleHealthStepData == null,
        [appleHealthStepData]
    )

    const requestSleepData = useCallback(() => {
        if (!shouldSyncSleepData || hasRequestedSleepData) {
            return
        }
        setHasRequestedSleepData(true)

        pullAppleHealthSleepData({
            startDateIso: oneMonthAgoIso,
            endDateIso: todayIso
        })
    }, [hasRequestedSleepData, oneMonthAgoIso, shouldSyncSleepData, todayIso])

    const requestStepsData = useCallback(() => {
        if (!shouldSyncStepsData || hasRequestedStepsData) {
            return
        }
        setHasRequestedStepsData(true)
        pullAppleHealthStepsData({
            startDateIso: oneMonthAgoIso,
            endDateIso: todayIso
        })
    }, [hasRequestedStepsData, oneMonthAgoIso, shouldSyncStepsData, todayIso])

    const handleProcessRawAppleHealthSleepData = useCallback(() => {
        if (
            !shouldSyncSleepData ||
            sleepDataLoading ||
            hasProcessedRawSleepData
        ) {
            return
        }

        try {
            // Validate/parse the raw sleep data
            const rawSleepData =
                AppleHealthSleepArray.parse(appleHealthSleepData)

            // Abort if there is no data
            if (rawSleepData.length === 0) return

            // Convert the raw sleep data to the records expected by the backend
            const sleepRecords = rawSleepData.map(
                ({ id, startDate, endDate, value }) => ({
                    appleHealthId: id,
                    thriveUserId: userId,
                    startDate: appleHealthDateToBackendSupportedDate(startDate),
                    endDate: appleHealthDateToBackendSupportedDate(endDate),
                    value
                })
            )

            processRawAppleHealthSleepData({ variables: { sleepRecords } })
        } catch (e) {
            captureSleepError(e, userId)
        } finally {
            setHasProcessedRawSleepData(true)
        }
    }, [
        appleHealthSleepData,
        hasProcessedRawSleepData,
        processRawAppleHealthSleepData,
        shouldSyncSleepData,
        sleepDataLoading,
        userId
    ])

    const syncStepsData = useCallback(() => {
        if (!shouldSyncStepsData || stepsDataLoading || hasSyncedStepsData) {
            return
        }

        try {
            // Validate/parse the raw steps data
            const rawStepsData =
                AppleHealthStepsArray.parse(appleHealthStepData)

            // Abort if there is no data
            if (rawStepsData.length === 0) return

            // Convert the raw steps data to the records expected by the backend
            const savableStepsData: Record<string, AppleHealthStepsInput> =
                rawStepsData.reduce((acc, rawStepsItem) => {
                    const steps = Math.floor(rawStepsItem.value)
                    const date = parseISO(rawStepsItem.startDate)
                    const dayOfYear = getDayOfYear(date)
                    const year = getYear(date)
                    const stepsDatumKey = `${dayOfYear}-${year}`
                    if (acc[stepsDatumKey] != null) {
                        return {
                            ...acc,
                            [stepsDatumKey]: {
                                ...acc[stepsDatumKey],
                                steps: acc[stepsDatumKey].steps + steps
                            }
                        }
                    }
                    const savableStepsDatum: AppleHealthStepsInput = {
                        dayOfYear,
                        year,
                        thriveUserId: userId,
                        steps
                    }
                    return {
                        ...acc,
                        [stepsDatumKey]: savableStepsDatum
                    }
                }, {} as Record<DateIso, AppleHealthStepsInput>)

            saveAppleHealthStepsData({
                variables: { stepsData: Object.values(savableStepsData) }
            })
        } catch (e) {
            captureStepsError(e, userId)
        } finally {
            setHasSyncedStepsData(true)
        }
    }, [
        appleHealthStepData,
        hasSyncedStepsData,
        saveAppleHealthStepsData,
        shouldSyncStepsData,
        stepsDataLoading,
        userId
    ])

    useEffect(() => {
        requestSleepData()
        requestStepsData()
        handleProcessRawAppleHealthSleepData()
        syncStepsData()
    }, [
        handleProcessRawAppleHealthSleepData,
        requestSleepData,
        requestStepsData,
        syncStepsData
    ])
}
