import { Box, Divider, Button } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useMemo, useCallback } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { POINTS_MESSAGE } from '../../utils/intlConstants'

const DURATION_MESSAGE = defineMessage({
    defaultMessage: '{durationMins, plural, one {# min} other {# mins}}.',
    description: 'formatted duration in minutes'
})

const DELETE_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Delete',
    description: 'delete button text'
})

const UNDO_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Undo',
    description: 'undo button text'
})

type ManualActivityRowProps = {
    activity: {
        durationMins: number
        steps: number
        label: string
        id: string
        date: string
    }
    toDelete: boolean
    toggleDelete: (activity: string) => void
}

const ManualActivityRow: React.FC<ManualActivityRowProps> = ({
    activity,
    toDelete,
    toggleDelete
}) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()

    const buttonText = useMemo(
        () => (toDelete ? UNDO_BUTTON_TEXT : DELETE_BUTTON_TEXT),
        [toDelete]
    )
    const textColor = useMemo(
        () => (toDelete ? theme.palette.text.disabled : undefined),
        [theme.palette.text.disabled, toDelete]
    )

    const handleButtonClick = useCallback(() => {
        toggleDelete(activity.id)
    }, [toggleDelete, activity.id])

    return (
        <Box>
            <Divider />
            <Box
                display="flex"
                paddingTop={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    aria-disabled={toDelete}
                >
                    <CoreTypography variant="h5" color={textColor}>
                        {activity.label}
                    </CoreTypography>
                    <CoreTypography component="div" color={textColor}>
                        {formatMessage(DURATION_MESSAGE, {
                            durationMins: activity.durationMins
                        })}{' '}
                        <CoreTypography
                            variant="h5"
                            component="span"
                            color={textColor}
                        >
                            {formatMessage(POINTS_MESSAGE, {
                                points: activity.steps
                            })}
                        </CoreTypography>
                    </CoreTypography>
                </Box>
                <Box>
                    <Button variant="text" onClick={handleButtonClick}>
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(buttonText)}
                        </CoreTypography>
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ManualActivityRow
