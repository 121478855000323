import { Card } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { POINTS_MESSAGE } from '../../utils/intlConstants'

const APPS_ACTIVITY_CARD_TITLE = defineMessage({
    defaultMessage: 'Health & Fitness apps',
    description: 'Health & Fitness apps activity card title'
})

type AppsActivityCountCardProps = {
    appsActivityTotal: number
}

const AppsActivityCountCard: React.FC<AppsActivityCountCardProps> = ({
    appsActivityTotal
}) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()
    return (
        <Card
            variant="outlined"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: 2
            }}
        >
            <CoreTypography
                variant="overline"
                color={theme.palette.text.primary}
            >
                {formatMessage(APPS_ACTIVITY_CARD_TITLE)}
            </CoreTypography>
            <CoreTypography variant="h5" color={theme.palette.text.primary}>
                {formatMessage(POINTS_MESSAGE, {
                    points: appsActivityTotal
                })}
            </CoreTypography>
        </Card>
    )
}

export default AppsActivityCountCard
