import { gql } from '@thriveglobal/thrive-web-core'

export const SAVE_ACTIVITY_ENTRY = gql`
    mutation SaveActivityEntry($activityEntry: ActivityEntryInput!) {
        wearables {
            activity {
                saveActivityEntry(activityEntry: $activityEntry) {
                    date
                    steps
                    durationMins
                    label
                }
            }
        }
    }
`

export const SAVE_APPLE_HEALTH_STEPS_DATA = gql`
    mutation SaveAppleHealthStepsData($stepsData: [AppleHealthStepsInput!]!) {
        wearables {
            activity {
                saveAppleHealthStepsActivity(stepsRecords: $stepsData) {
                    __typename
                }
            }
        }
    }
`

export const PROCESS_RAW_APPLE_HEALTH_SLEEP_DATA = gql`
    mutation ProcessRawAppleHealthSleepData(
        $sleepRecords: [AppleHealthSleepBlocksInput!]!
    ) {
        wearables {
            sleep {
                saveAppleHealthSleepBlocks(sleepRecords: $sleepRecords)
            }
        }
    }
`

export const SAVE_USER_WELL_BEING_SOURCE = gql`
    mutation SaveUserWellBeingSource($source: WellBeingSourceInput!) {
        wearables {
            users {
                createWellBeingSource(source: $source) {
                    sourceId
                    sourceName
                    created
                    connected
                    lastSynced
                }
            }
        }
    }
`

export const UPDATE_ACTIVITY_SUMMARY = gql`
    mutation updateActivitySummary(
        $localTimestamp: String
        $localUTCTimezoneOffsetInMinutes: Int
    ) {
        wearables {
            updateActivitySummary(
                localTimestamp: $localTimestamp
                localUTCTimezoneOffsetInMinutes: $localUTCTimezoneOffsetInMinutes
            ) {
                steps
                updatedAt
                calories
                distance
            }
        }
    }
`

export const UPDATE_USER_SOURCE_CONNECTION_STATUS = gql`
    mutation UpdateUserSourceConnectionStatus(
        $sourceId: UUID!
        $connected: Boolean!
    ) {
        wearables {
            users {
                updateWellBeingConnectionStatus(
                    sourceId: $sourceId
                    connected: $connected
                )
            }
        }
    }
`

export const DELETE_MANUAL_ACTIVITY_ENTRIES = gql`
    mutation DeleteManualActivityEntries($date: ISODate!, $entryIds: [UUID!]!) {
        wearables {
            activity {
                deleteManualActivityEntries(date: $date, entryIds: $entryIds)
            }
        }
    }
`

export const DISCONNECT_OURA_USER = gql`
    mutation DisconnectOuraUser {
        wearables {
            oura {
                disconnect
            }
        }
    }
`

export const SYNC_HAPI_SOURCES = gql`
    mutation SyncHapiSources {
        wearables {
            humanApi {
                syncSources
            }
        }
    }
`
