import { Box } from '@mui/material'
import React from 'react'
import { WearablesConnectOrProgress } from '../WearablesConnectOrProgress'

export type WearablesWithPrivacyProps = {
    userChallengeRefetch: () => Promise<any>
}

export const WearablesWithPrivacy: React.FC<WearablesWithPrivacyProps> = ({
    userChallengeRefetch
}) => {
    return (
        <Box>
            <WearablesConnectOrProgress refetch={userChallengeRefetch} />
        </Box>
    )
}

export default React.memo(WearablesWithPrivacy)
