import {
    DialogContent,
    Button,
    Box,
    DialogActions,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { useIntl } from 'react-intl'
import {
    HOW_TO_DISCONNECT_DIALOG_TITLE,
    PRIVACY_BUTTON_TEXT
} from '../../utils/appleHealthDialogConstants'
import AppleHealthHowToDisconnectDialogContent from './AppleHealthHowToDisconnectDialogContent'

type AppleHealthHowToDisconnectDialogProps = {
    open: boolean
    onClose: () => void
    onShowPrivacyInfo: () => void
}

const AppleHealthHowToDisconnectDialog: React.FC<
    AppleHealthHowToDisconnectDialogProps
> = ({ open, onClose, onShowPrivacyInfo }) => {
    const intl = useIntl()
    const theme = useTheme()
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <LeafDialog
            data-testid="wearables-how-to-disconnect-dialog"
            open={open}
            dialogTitle={intl.formatMessage(HOW_TO_DISCONNECT_DIALOG_TITLE)}
            onClose={onClose}
            fullScreen={isMobileScreen}
        >
            <DialogContent>
                <AppleHealthHowToDisconnectDialogContent />
            </DialogContent>
            <DialogActions>
                <Box display="flex" width="100%">
                    <Box>
                        <Button variant="text" onClick={onShowPrivacyInfo}>
                            <CoreTypography customVariant="buttonNormal">
                                {intl.formatMessage(PRIVACY_BUTTON_TEXT)}
                            </CoreTypography>
                        </Button>
                    </Box>
                </Box>
            </DialogActions>
        </LeafDialog>
    )
}

export default AppleHealthHowToDisconnectDialog
