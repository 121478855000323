import z from 'zod'

export const AppleHealthStepsSample = z.object({
    startDate: z.string().datetime({ offset: true }),
    endDate: z.string().datetime({ offset: true }),
    value: z.number()
})

export type AppleHealthStepsSample = z.infer<typeof AppleHealthStepsSample>

export const AppleHealthSleepSample = z.object({
    id: z.string().uuid(),
    startDate: z.string().datetime({ offset: true }),
    endDate: z.string().datetime({ offset: true }),
    value: z.string(),
    sourceId: z.string()
})

export type AppleHealthSleepSample = z.infer<typeof AppleHealthSleepSample>

export const AppleHealthStepsArray = z.array(AppleHealthStepsSample)

export type AppleHealthStepsArray = z.infer<typeof AppleHealthStepsArray>

export const AppleHealthSleepArray = z.array(AppleHealthSleepSample)

export type AppleHealthSleepArray = z.infer<typeof AppleHealthSleepArray>
